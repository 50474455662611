import { Table, TableCell, TableBody, TableRow, TableContainer, Paper, Chip} from "@mui/material";
import { RESUME_FEILDS, JD_FIELDS } from "@constants/tableFields";
import styles from "./DocumentTableView.module.css";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";

const CustomTabelCell = ({sx, ...params})=>{
    return (
    <TableCell 
        sx={{
            fontFamily:"Montserrat",
            fontSize:"0.85rem",
            background:"white",
            width:"50%",
            border:"none",
            ...sx
        }}
        {...params}
    >
        {params.children}
    </TableCell>
)}

const NotDetected = ({text}) => {
    return (
        <span style={{
            color: 'lightgray'
        }}>
            {text ? text : 'Not Detected'}
        </span>
    )
}

const ChipsArray = ({ chipData }) => {
    return (
      <div className={styles.chipsContainer}>
          {
          chipData.length ? 
          chipData.map((data, index) => (
            <Chip key={index} label={data} className={styles.chip}
                sx={{
                    height:"1.8rem",
                    border : "1px double lightgray",
                    maxWidth : "fit-content",
                    overflow : "auto"
                }}
            />
          ))
          : <NotDetected />
          }
      </div>
    );
}

const TimelineRenderer = ({item, type}) => {
    return (
        <Timeline sx={{padding : "0.5rem 0rem"}}>
        {
            item.map((subItem, index)=>(
                <TimelineItem key={index} sx={{padding:"0"}}>
                    <TimelineOppositeContent
                        align="right"
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{paddingLeft:"0"}}
                    >
                        {
                            type === 'education' ? (
                                <div>
                                    <div className={styles.oppositeHeader}>{subItem?.degree}</div>
                                    <div className={styles.oppositeSubHeader} >{subItem?.branch}</div>
                                </div>
                            ) :
                            type === "work_experience" ? (
                                <div>
                                    <div className={styles.oppositeHeader}>{subItem?.designation}</div>
                                </div>
                            ) : null
                        }
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color={type === "education" ? "warning" : "primary"} variant="outlined" />
                        {index >= (item?.length - 1) ? null : <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            type === "education" ? (
                                <div className={styles.timelineContent}>
                                    <div className={styles.timelineHeader}>{subItem?.institute_name}</div>
                                    <div className={styles.timelineSubHeader}>{subItem?.date}</div>
                                </div>
                            ) :
                            type === "work_experience" ? (
                                <div className={styles.timelineContent}>
                                    <div className={styles.timelineHeader}>{subItem?.company_name}</div>
                                    <div className={styles.timelineSubHeader} >{subItem?.start_date } {subItem?.start_date && "to"} {subItem?.end_date || "Present"}</div>
                                </div>
                            ) : null
                        }
                    </TimelineContent>
                </TimelineItem>
            ))
        }
        </Timeline>
    )
}

const DocumentTableView = ({document}) => {
    const renderValue = (type, item, field) => {
        if(type === "string") return item;
        if(type === "chip-array") return <ChipsArray chipData={item} />; 
        if(type === "timeline") return <TimelineRenderer item={item} type={field} />; 
        return item;
    }

    return (
        <>
            <TableContainer component={(props)=><Paper elevation={0} {...props} />} style={{width:"99%", top:"0", fontFamily:"Montserrat", border:"1px solid black", borderRadius:"20px"}}>
                <Table sx={{ width: '100%', height:"100%" }} aria-label="simple table">
                    {/* <TableHead>
                        <TableRow style={{background:"white"}}>
                            <CustomTabelCell sx={{fontWeight:"700", width:"30%", background:"#EA723C1A"}}>Field</CustomTabelCell>
                            <CustomTabelCell sx={{fontWeight:"700", background:"#EA723C1A"}}>Value</CustomTabelCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>
                        {
                            Object.keys(document.document_type === 'resumes' ? RESUME_FEILDS : JD_FIELDS).map((key, index) => (
                                <TableRow key={index} sx={{border:"1px dashed lightgray"}}>
                                    <CustomTabelCell
                                        sx={{
                                            fontWeight:"600",
                                            width : "30%",
                                            background : index % 2 === 0 ? "#f3f3f3" : "white",
                                        }}
                                    >{(document.document_type === 'resumes' ? RESUME_FEILDS : JD_FIELDS)[key].text}</CustomTabelCell>
                                    <CustomTabelCell sx={{
                                        padding : "0.5rem 0rem",
                                        background : index % 2 === 0 ? "#f3f3f3" : "white",
                                    }}>{
                                        ['n/a', 'none', 'null'].includes(String(document[key]).toLocaleLowerCase()) ? <NotDetected /> : renderValue((document.document_type === 'resumes' ? RESUME_FEILDS : JD_FIELDS)[key].type, document[key], key)
                                    }</CustomTabelCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DocumentTableView;