import { useLocation, useNavigate } from 'react-router-dom';

const useSearchParams = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const updateSearchParam = (update) => {
        const currentParams = new URLSearchParams(location.search);
        for(let [key, value] of Object.entries(update)){
            if(value){
                currentParams.set(key, value);
            }else{
                currentParams.delete(key);
            }
        }

        if(currentParams.size){
            navigate(`?${currentParams.toString()}`, { replace: true });
        }else{
            navigate("", {replace:true})
        }
    }

    return updateSearchParam;
}

export default useSearchParams;