const ViewJobIcon = ({color="#232322", ...params}) => {
    return (
        <svg width="32" height="32" {...params} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_460_11404" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="white"/>
        </mask>
        <g mask="url(#mask0_460_11404)">
        <path d="M16.0015 19.1535L16.0015 20.7322" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.52979 14.9497L6.6907 17.963C6.86359 21.7661 6.95004 23.6677 8.17058 24.8339C9.39112 26 11.2949 26 15.1026 26H16.9033C20.7109 26 22.6147 26 23.8353 24.8339C25.0558 23.6677 25.1423 21.7661 25.3151 17.963L25.4761 14.9497" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.36543 14.3574C8.15406 17.7581 12.1883 19.1531 15.9994 19.1531C19.8106 19.1531 23.8448 17.7581 25.6334 14.3574C26.4872 12.734 25.8407 9.68143 23.7379 9.68143H8.26092C6.15813 9.68143 5.51164 12.734 6.36543 14.3574Z" stroke={color} strokeWidth="1.2"/>
        <path d="M20.2111 9.68344L20.1181 9.35818C19.655 7.73742 19.4234 6.92704 18.8721 6.46352C18.3208 6 17.5885 6 16.1239 6H15.8777C14.4131 6 13.6808 6 13.1295 6.46352C12.5782 6.92704 12.3466 7.73742 11.8835 9.35818L11.7905 9.68344" stroke={color} strokeWidth="1.2"/>
        <path d="M21.168 19.4543C21.3417 19.6979 21.4286 19.8197 21.4286 20C21.4286 20.1803 21.3417 20.3021 21.168 20.5457C20.3874 21.6403 18.3938 24 15.7143 24C13.0347 24 11.0412 21.6403 10.2606 20.5457C10.0869 20.3021 10 20.1803 10 20C10 19.8197 10.0869 19.6979 10.2606 19.4543C11.0412 18.3597 13.0347 16 15.7143 16C18.3938 16 20.3874 18.3597 21.168 19.4543Z" fill="white" stroke={color} strokeWidth="1.2"/>
        <path d="M17.4291 20C17.4291 19.0532 16.6615 18.2857 15.7148 18.2857C14.768 18.2857 14.0005 19.0532 14.0005 20C14.0005 20.9468 14.768 21.7143 15.7148 21.7143C16.6615 21.7143 17.4291 20.9468 17.4291 20Z" fill="white" stroke={color} strokeWidth="1.2"/>
        </g>
        </svg>
    )
}

export default ViewJobIcon;