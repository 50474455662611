import { ticketsAPI } from "./axiosIstances";

export const fetchTickets = async () => {
    const response = await ticketsAPI.get("/");
    return response.data;
}

export const raiseTicket = async (formData) => {
    const response = await ticketsAPI.post("/", formData, {
        headers:{
            'Content-Type' : "form/multi-part"
        }
    });
    return response.data;
}
