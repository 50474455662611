import { AttachIcon, DeleteIcon } from "@icons/ActionsIcons";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { CustomAutoComplete, CustomTextField } from "@components/CustomMUIInputs";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import "./raise-a-ticket-form.css";
import { useState } from "react";
import { ResumesIcon } from "@icons/DashboardIcons";
import { getFormJson } from "@utils/formUtils";

const ALLOWED_TYPES = ['.jpg', '.jpeg', '.png', '.svg', '.pdf', '.docx', '.txt'];

const RaiseATicketForm = ({open, onSubmit=()=>{}, onClose=()=>{}, allowedTypes=ALLOWED_TYPES, maxFileSize= (10 * 1024 * 1024)}) => {
    const [files, setFiles] = useState([]);

    const handleFileInputChange = (event) => {
        setFiles(Array.from(event.target.files).map(file=>{
            const extension = file.name?.split(".").pop().toLowerCase();
            if(allowedTypes && !allowedTypes.includes(`.${extension}`)){
                return {name:file.name, error : "File type not supported!"}
            }

            if(maxFileSize && (file.size > maxFileSize)){
                return {name:file.name, error : "File size limit exceeded!"}
            }
            
            return file;
        }));
        event.target.value = "";
    }

    const handleFileDelete = (file) => {
        setFiles(prev=>prev.filter(item => item !== file));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            ...getFormJson(event),
            attachments : files.filter(file => !file.error)
        }
        setFiles([]);
        onSubmit(data);
    }

    const handleClose = ()=>{
        setFiles([]);
        onClose();
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:handleSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>Raise a Ticket</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <CustomAutoComplete 
                    freeSolo
                    required
                    name={"subject"}
                    label={"Reason"}
                    id={"reasonTicket"}
                    placeholder={"Select or Enter the Reason"}
                    options={['Feedback', 'Billing', 'Page Error']}
                />  

                <CustomTextField 
                    required
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                />

                <div className="raise-a-ticket-attachments">
                    <input 
                        type="file" id="attachments" 
                        multiple hidden 
                        onChange={handleFileInputChange}
                    />

                    <label htmlFor="attachments" className="raise-a-ticket-input-label">
                        <AttachIcon width="30px" height="30px" />
                        <div>
                            Attachments <span>(optional)</span>
                        </div>
                    </label>
                    <Collapse in={!!files.length}>
                        <div className="attachments-container">
                            {
                                files.map((file, idx)=>(
                                    <div key={idx} className="attachment-item">
                                        <div className="attachment-item-details">
                                            <ResumesIcon className="attachment-icon" color="#EA723C" />
                                            <div className="attachment-item-filename">
                                                <div>{file.name}</div>
                                                <div className={`file-detail ${file.error ? "error" : ""}`}>{file.error ? file.error : (file.size / 1024).toFixed(2) + " KB"}</div>
                                            </div>
                                        </div>
                                        <div className="attachment-item-action">
                                            <IconButton onClick={()=>handleFileDelete(file)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Collapse>
                </div>
                            
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                <Button
                    type={"submit"}
                    variant="contained"
                    style={{
                        background:"#EA723C",
                        textTransform:"none",
                        width:"100%",
                        height:"2.8rem",
                        margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                        borderRadius:"6px",
                        fontWeight:"600",
                    }} 
                >Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default RaiseATicketForm;