import { documentStatusRenderMap } from "@constants/documentStatusRenderMap";
import { DownArrowIcon } from "@icons/DialogsIcons";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const CustomStatusSelect = ({id, placeholder, sx, options, menuItemStyle, disabled=false, value="", onChange=(event, value, ...params)=>{}, ...props}) => {
    const [color, setColor] = useState(documentStatusRenderMap[value]?.color || "darkgray");
    const [background, setBackground] = useState(documentStatusRenderMap[value]?.background || "lightgray");

    const handleChange = (event)=>{
        const value = event.target?.value;
        setColor("darkgray");
        setBackground("lightgray");
        onChange(event, value);
    }

    useEffect(()=>{
        setColor(documentStatusRenderMap[value]?.color || "darkgray");
        setBackground(documentStatusRenderMap[value]?.background || "lightgray");
    }, [value])

    return (
        <div
            style={{
                width:"100%",
                display:"flex",
                alignItems : "center",
                justifyContent : "center",
                flexDirection:"column"
            }}
        >
            <FormControl
                margin="none"
                sx={{
                width: "100%",
                minHeight:"0",
                height: "fit-content",
                "& .MuiOutlinedInput-root": {
                    transition:"color ease-in-out 200ms, background-color ease-in-out 200ms",
                    borderRadius: "6px",
                    height:sx?.height,
                    color: color,
                    background: background,
                    textAlign: "left",
                    fontWeight: 600,
                    fontVariant:"all-petite-caps",
                    fontSize: "0.9rem",
                    padding: "0rem 0.2rem",
                    "& fieldset": {
                    border:"none",
                    },
                    "&:hover fieldset": {
                    border:"none",
                    },
                    "&.Mui-focused fieldset": {
                    border:"none",
                    },
                    "&.Mui-disabled": {
                        background:"lightgray"
                    },
                },
                "& .MuiSelect-select": {
                    minHeight:"0px",
                    "&:hover": {
                    },
                },
                "& .MuiInputLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                    color: "rgb(234, 114, 60)",
                    },
                },
                ...sx
                }}
            >
                <Select
                    id={id}
                    labelId={`label-${id}`}
                    value={value}
                    {...props}
                    displayEmpty
                    disabled={disabled}
                    onChange={handleChange}
                    IconComponent={DownArrowIcon}
                    renderValue={(value) => documentStatusRenderMap[value]?.text}
                >
                {options && options.length > 0 ? (
                    options.map((name, index) => (
                    <MenuItem
                        value={name}
                        key={index}
                        style={{ textTransform: "capitalize", ...menuItemStyle }}
                    >
                        <div
                            style={{
                                color: documentStatusRenderMap[name]?.color,
                                background: documentStatusRenderMap[name]?.background,
                                fontSize:"0.8rem",
                                height:"1.5rem",
                                display:"flex",
                                alignItems:"center",
                                padding:"0.1rem 0.5rem",
                                borderRadius:"8px"
                            }}
                        >
                            {documentStatusRenderMap[name]?.text}
                        </div>
                    </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" disabled>
                        No status available
                    </MenuItem>
                )}
                </Select>
            </FormControl>
        </div>
    )
}

const StatusRenderer = ({value, data:document, onUpdate = ()=>{}, ...params}) => {
    const filteredOptions = useMemo(()=>{
        return Object.entries(documentStatusRenderMap)
                .filter(([k, v]) => v.documentType === document?.document_type)
                .map(([k, v])=>k)
    }, [document?.document_type])
    
    const [isUpdating, setIsUpdating] = useState(false);
    
    const handleStatusChange = (event, newValue) => {
        setIsUpdating(true);
        onUpdate(document?._id, newValue);
    }

    useEffect(()=>{
        setIsUpdating(false);
    }, [value])

    return (
        <div className="centered-cell"
            style={{width : "100%"}}
        >
            <CustomStatusSelect
                options={filteredOptions}
                sx={{
                    height:"2rem",
                    width : "12.3rem",
                    maxWidth : "100%"
                }}
                value={value}
                id={document?._id + "select"}
                onChange={handleStatusChange}
                disabled={isUpdating}
            />
        </div>
    )
};

export default StatusRenderer;