import { documentsAPI } from "./axiosIstances";

export const fetchDocuments = async () => {
    const response = await documentsAPI.get("/");
    return response.data;
}

export const fetchArchivedDocuments = async () => {
    const response = await documentsAPI.get("/?status=archived");
    return response.data;
}

export const getFile = async (documentID) => {
    const response = await documentsAPI.get(`/file/${documentID}`, {
        withCredentials:true,
        responseType: 'blob'
    });
    return response.data;
}

export const addDocuments = async (documentsData) => {
    const response = await documentsAPI.post("/", documentsData, {
        headers:{
            'Content-Type' : "form/multi-part"
        }
    });
    return response.data;
}

export const generateJD = async (prompt) => {
    const response = await documentsAPI.post("/job-descriptions/generate", {prompt});
    return response.data;
}

export const updateDocument = async ([documentID, update]) => {
    const response = await documentsAPI.patch(`/${documentID}`, update);
    return response.data;
}

export const deleteDocument = async (documentIDs, isBatch=false) => {
    if(isBatch){
        await documentsAPI.delete('/batch', {
            data:{document_ids:documentIDs},
            withCredentials:true
        });
    }else{
        await documentsAPI.delete(`/${documentIDs}`);
    }
    return documentIDs;
}

export const getSimilarityScore = async (documentID) => {
    const result = await documentsAPI.get(`/similarity-scores/${documentID}`);
    return result.data;
}