const StatusRenderer = (params) => {
    const renderMap = {
        null : {
            text : "Pending",
            background : '#FFF1D6',
            color : '#B86F01'
        },
        true : {
            text : "Active",
            background : '#DBF6E5',
            color : '#1A925D'
        },
        false : {
            text : "Banned",
            background : '#FFE4DE',
            color : "#C4423D"
        }
    }
    return (
        <div className="centered-cell">
            <div
                style={{
                    width:"8rem",
                    maxWidth: "70%",
                    height:"45%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    background : renderMap[params.value].background,
                    color : renderMap[params.value].color,
                    borderRadius:"6px",
                    fontWeight: 600,
                    fontSize: "1rem",
                    fontVariant:"all-petite-caps",
                    userSelect: "none"
                    
                }}
            >
                {renderMap[params.value].text}
            </div>
        </div>
    )
};

export default StatusRenderer;