import { webCareersAPI } from "./axiosIstances";

export const getJobInfo = async (jobID) => {
    const response = await webCareersAPI.get(jobID);
    return response.data;
}

export const applyForJob = async (jobID, webCareerForm) => {
    const response = await webCareersAPI.post(`/${jobID}`, webCareerForm, {
        headers:{
            'Content-Type' : "form/multi-part"
        }
    });
    return response.data;
}