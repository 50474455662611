import "./login.css";
import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";

import AppLogo from "@icons/AppLogo";
import { WordsAnimation } from "@components"
import { useAuth } from "@contexts/AuthContext";
import LoginPageImage from "@icons/LoginPageImage";
import useResponsiveView from "@hooks/useResponsiveView"
import { CustomTextField } from "@components/CustomMUIInputs";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useDocuments } from "@contexts/DocumentsContext";

const Login = () => {
    const isMobile = useResponsiveView();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const { login, loading, errors } = useAuth();
    const queryClient = useQueryClient();
    const { reset } = useDocuments();

    const handleLogin = async (event)=>{
        event.preventDefault();
        const formData = new FormData(event.target);
        const formJson = Object.fromEntries(formData.entries());
        await login(formJson);
        reset();
        queryClient.invalidateQueries();
        queryClient.refetchQueries(['users']);
    }

    useEffect(()=>{
        const error = errors.loginError;
        if(error === "") return;
        setEmailError((error && error.toLowerCase().includes("user")) ? error : "")
        setPasswordError((error && error.toLowerCase().includes("password")) ? error : "")
        if(error.toLowerCase().includes("pending")){
            toast.warning("Pending Verification", {closeOnClick:true});
        }else if(error.toLowerCase().includes("revoked")){
            toast.error("Access Denied", {closeOnClick:true});
        }
    }, [errors])

    return (
        <>
            <div className="login-container">
                <div className="login-left">
                    <div className="login-left-inner">
                        <AppLogo className={"login-app-logo"} />
                        {
                            isMobile ?
                            <div className="login-image-container">
                                <LoginPageImage className="login-image" />
                            </div>
                            : null
                        }
                        <div className="login-form">
                            <div className="login-header">
                                <h3>Login</h3>
                                <p>Let's get started <span className="wave-emoji">👋</span></p>
                            </div>
                            <form onSubmit={handleLogin} className="login-form-box">
                                <CustomTextField 
                                    variant="outlined"
                                    label={"Email Address"}
                                    placeholder="Enter your Email ID"
                                    name="username"
                                    id="username"
                                    sx={{    
                                        fontFamily : "Montserrat",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius:"10px",
                                            border: "2px solid #3F3F3F"
                                        }
                                    }}      
                                    required
                                    error={!!emailError}
                                    helperText={emailError}
                                    value={email}
                                    onChange={(event)=>setEmail(event.target.value)}
                                />
                                <CustomTextField 
                                    variant="outlined"
                                    label={"Password"}
                                    placeholder="Enter your password"
                                    name="password"
                                    type="password"
                                    id="password"
                                    sx={{
                                        fontFamily : "Montserrat",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius:"10px",
                                            border: "2px solid #3F3F3F"
                                        }
                                    }}
                                    required
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    value={password}
                                    onChange={(event)=>setPassword(event.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        margin:isMobile ? "1rem 0 0.5rem 0" : "3rem 0 1rem 0",
                                        background:"#EA723C",
                                        borderRadius:"1rem",
                                        textTransform:"none",
                                        fontFamily:"Montserrat",
                                        fontSize:"1.1rem",
                                        fontWeight:"600",
                                        width:"100%",
                                        height:"3rem",
                                        "&:hover" : {
                                            background : "#EA723C",
                                            boxShadow:"2px 2px 5px 1px #EA723C"
                                        },
                                        ":disabled" : {
                                            background : "#EA723C"
                                        }
                                    }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={"1.5rem"} sx={{color:"white"}} /> : "Login"}
                                </Button>
                            </form>
                        </div>
                        {/* <footer>Powered by <Link to={COMPANY_URL} target="blank"><strong>Lemolite Technologies LLP</strong></Link></footer> */}
                    </div>
                </div>
                {
                isMobile ? null :
                <div className="login-right">
                    <div className="login-quote">
                        <h3>Make Your <br /> Hiring Process</h3>
                        <WordsAnimation words={["EASY!", "EFFECTIVE!", "ACCURATE!", "FASTER!"]} />
                    </div>
                    <div className="login-image-container">
                        <LoginPageImage className="login-image" />
                    </div>
                </div>
                }
            </div>
        </>
    )
}

export default Login;