const ArchiveIcon = ({color="#232322", ...params}) => (
    <svg width="32" height="32" viewBox="0 0 32 32" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_697_6056" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
    <rect x="0.5" y="0.5" width="31" height="31" fill="#D9D9D9" stroke="black"/>
    </mask>
    <g mask="url(#mask0_697_6056)">
    <path d="M6 20C6 17.6611 6 16.4917 6.53646 15.6379C6.81621 15.1927 7.19267 14.8162 7.63788 14.5365C8.49166 14 9.6611 14 12 14H19.9999C22.3388 14 23.5083 14 24.362 14.5365C24.8073 14.8162 25.1837 15.1927 25.4635 15.6379C25.9999 16.4917 25.9999 17.6611 25.9999 20C25.9999 22.3389 25.9999 23.5083 25.4635 24.3621C25.1837 24.8073 24.8073 25.1837 24.362 25.4635C23.5083 26 22.3388 26 19.9999 26H12C9.6611 26 8.49166 26 7.63788 25.4635C7.19267 25.1837 6.81621 24.8073 6.53646 24.3621C6 23.5083 6 22.3389 6 20Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.999 14C23.999 12.5999 23.999 11.8998 23.7265 11.365C23.4868 10.8946 23.1043 10.5122 22.6339 10.2725C22.0992 10 21.3991 10 19.999 10H11.999C10.5989 10 9.89882 10 9.36404 10.2725C8.89364 10.5122 8.51119 10.8946 8.27151 11.365C7.99902 11.8998 7.99902 12.5999 7.99902 14" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.0009 9.99998C22.0009 8.11437 22.0009 7.17157 21.4151 6.58578C20.8294 6 19.8866 6 18.0009 6H14.001C12.1153 6 11.1725 6 10.5868 6.58578C10.001 7.17157 10.001 8.11437 10.001 9.99998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.9985 18C18.9985 19.1046 18.1031 20 16.9985 20H14.9985C13.894 20 12.9985 19.1046 12.9985 18" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>
)

export default ArchiveIcon;