import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./QuickLinkCard.module.css";
import { QuickLinkArrowIcon } from "@icons/ActionsIcons";
import { Fade } from "@mui/material";

const QuickLinkCard = ({
    title, content="", to="#", CardIcon = ()=>{},
    style = {},
    className = "",
    color = "black",
    rgbBackground = [0, 0, 0],
    ...params
}) => {

    const [active, setActive] = useState(false);
    const getBackground = useCallback((opacity)=>{
        const [r, g, b] = rgbBackground;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`
    }, [rgbBackground])

    return (
        <Link
            style={{
                color: color,
                border: "1px solid",
                background: active ? getBackground(0.15) : getBackground(0.08),
                borderColor: active ? 
                    `transparent ${getBackground(0.25)} ${getBackground(0.25)} transparent`  
                    : "transparent",
                ...style,
            }}
            className={`${styles.quickLinkCard} ${className}`}
            onMouseEnter={()=>setActive(true)}
            onMouseLeave={()=>setActive(false)}
            to={to}
            {...params}
        >
            <CardIcon 
            style={{
                color: color,
                background: active ? getBackground(0.5) : getBackground(0.2),
            }}
            className={styles.quickLinkCardIcon} />
            <div className={styles.content}>
                <div className={styles.contentBox}>
                    <div className={styles.contentTitle}>
                        {title}
                    </div>
                    {content}
                </div>
                <Fade in={active} timeout={500}>
                    <div className={styles.arrowBox}>
                        <QuickLinkArrowIcon className={styles.arrow} />
                    </div>
                </Fade>
            </div>
        </Link>
    )
}

export default QuickLinkCard;
