const UploadIcon = ({color="#7A7A7A", ...params}) => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0.999878H10.2727C13.5339 0.999878 15.1645 0.999878 16.2969 1.79771C16.6214 2.02631 16.9094 2.2974 17.1523 2.60277C18 3.66855 18 5.20324 18 8.27261V10.8181C18 13.7812 18 15.2628 17.5311 16.4461C16.7772 18.3485 15.1829 19.849 13.1616 20.5585C11.9044 20.9999 10.3302 20.9999 7.18182 20.9999C5.38275 20.9999 4.48322 20.9999 3.76478 20.7477C2.60979 20.3422 1.69875 19.4848 1.26796 18.3977C1 17.7216 1 16.8749 1 15.1817V10.9999" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 11C18 12.8409 16.5076 14.3333 14.6667 14.3333C14.0009 14.3333 13.216 14.2167 12.5686 14.3901C11.9935 14.5442 11.5442 14.9935 11.3901 15.5686C11.2167 16.216 11.3333 17.0009 11.3333 17.6667C11.3333 19.5076 9.84095 21 8 21" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 3.49988C2.49153 2.99418 3.79977 0.999878 4.5 0.999878M7 3.49988C6.50847 2.99418 5.20023 0.999878 4.5 0.999878M4.5 0.999878L4.5 8.99988" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default UploadIcon;