export const  validateName = (name)=>{
    if (name.length < 3) {
        return { isValid: false, message: "Name must be at least 3 characters long." };
    }
    
    const nameRegex = /^[A-Za-z ]+$/;
    if (!nameRegex.test(name)) {
        return { isValid: false, message: "Name can only contain letters and spaces." };
    }
    
    return { isValid: true, message: "Name is valid." };
}

export const validatePassword = (password)=>{
    if (password.length < 8) {
        return { isValid: false, message: "Password must be at least 8 characters long." };
    }

    const lowerCaseRegex = /[a-z]/;
    const upperCaseRegex = /[A-Z]/;
    const digitRegex = /\d/;

    if (!lowerCaseRegex.test(password)) {
        return { isValid: false, message: "Password must contain at least one lowercase letter." };
    }
    if (!upperCaseRegex.test(password)) {
        return { isValid: false, message: "Password must contain at least one uppercase letter." };
    }
    if (!digitRegex.test(password)) {
        return { isValid: false, message: "Password must contain at least one digit." };
    }
    
    // const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    // if (!specialCharRegex.test(password)) {
    //     return { isValid: false, message: "Password should contain at least one special character." };
    // }

    return { isValid: true, message: "Password is valid." };
}

export const validateInputs = (formJson, fields) => {
    const response = {isValid: true, errors: {}}
    for(let field of fields){
        const value = formJson[field.name];
        const {isValid, message} = field.type === "name" ? validateName(value) : validatePassword(value);
        response.isValid &= isValid;
        if(!isValid){
            response.errors[field.name] = message;
        }
    }

    return response;
}