import { CrossCircleIcon } from "@icons/DialogsIcons";
import { FileDropInput } from "@components";
import styles from "./uploadResumeDialog.module.css";
import { CustomAutoComplete } from "@components/CustomMUIInputs";
import { useDocuments } from "@contexts/DocumentsContext";

const { Dialog, DialogTitle, DialogContent, DialogActions, Button } = require("@mui/material")
const { useState } = require("react")

const UploadResumeDialog = ({open, onSubmit=()=>{}, onClose=()=>{}}) => {
    const [uploads, setUploads] = useState([]);
    const [target, setTarget] = useState(null);
    const [error, setError] = useState("");
    const { documents } = useDocuments("job-descriptions")

    const handleClose = ()=>{
        setUploads([]);
        setTarget(null);
        setError("");
        onClose();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!uploads.length){
            setError("No File Selected");
            return;
        }
        onSubmit({
            files:uploads, additionalDetails:target ? {target: target._id} : null
        })
        handleClose();
    }
    return (
        <Dialog fullWidth open={open} onClose={handleClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:handleSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>Upload Resume(s)</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    position:"relative",
                }}
            >
                <CustomAutoComplete
                    id="jd-selection"
                    options={documents || []}
                    getOptionKey={(document)=>document._id}
                    getOptionLabel={(document)=>document ? document.filename : ''}
                    sx={{ 
                        width: "100%", padding : "0rem 0rem", boxSizing : "border-box", borderStyle:"dashed"
                    }}
                    label={"Job description"}
                    value={target}
                    onChange={(e, newValue)=>setTarget(newValue)}
                    placeholder="Select Target JD (Optional)"
                />
                <hr />
                <label className={styles.uploadLabel} htmlFor="resume-upload">Upload Resumes</label>
                <FileDropInput id={"resume-upload"} defaultValue={uploads} onChange={setUploads} error={!!error} helperText={error}/>   
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                <Button
                    type={"submit"}
                    variant="contained"
                    style={{
                        background:"#EA723C",
                        textTransform:"none",
                        width:"100%",
                        height:"2.8rem",
                        margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                        borderRadius:"6px",
                        fontWeight:"600"
                    }} 
                >Upload</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadResumeDialog;