import "./dashboard.css";

import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Paper, Drawer, IconButton, Button, Fade } from "@mui/material";

import AppLogo from "@icons/AppLogo";
import { SiteTour } from "@components";
import TOUR_STEPS from "@constants/tourSteps";
import MENU_ITEMS from "@constants/menuItems";
import { useAuth } from "@contexts/AuthContext";
import { APP_VERSION } from "@constants/appConstants";
import useResponsiveView from "@hooks/useResponsiveView";
import { LogoutIcon, HamburgerButtonIcon } from "@icons/DashboardIcons";
import { UploadStatusDialog } from "@components/index";
import CustomBreadcrumbs from "@components/CustomBreadcrumbs/CustomBreadcrumbs";

const Dashboard = () => {
    const [menu, setMenu] = useState("..." );
    const [tour, setTour] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useResponsiveView();
    const { user, logout } = useAuth();
    const [daysLeft, setDaysLeft] = useState(null);
    
    useEffect(()=>{
        let tmpDate = new Date(user.plan.start_date);
        tmpDate = tmpDate.setMonth(tmpDate.getMonth() + user.plan.duration_in_months)
        setDaysLeft(Math.ceil((tmpDate - new Date()) / (3600 * 1000 * 24)));

        const seen_tour = localStorage.getItem("seenTour")
        if(!seen_tour && !user.seen_help){
            setTour(!user.seen_help);
            localStorage.setItem("seenTour", "true")
        }
        setMenu(MENU_ITEMS.find(x=> x.path !== "/" && location.pathname.startsWith(x.path))?.name || "Home")
    }, [user, location]);

    const handleLogout = () => {
        localStorage.clear("seenTour");
        logout();
    }

    // Mobile Services
    const [drawerOpen, setDrawerOpen] = useState(false);
    
    // Upload Status Dialog
    const [uploadDialog, setUploadDialog] = useState('');
    // const {resumeQueue, jdQueue, setResumeQueue, setJdQueue} = useUploadQueue();
    
    return (
        <>
        <div className="upload-status-container-wrapper">
            <UploadStatusDialog 
                title={'resume'}
                documentType={"resumes"} 
                active={(uploadDialog === 'resume')} 
                onExpand={()=>{setUploadDialog('resume')}} 
                onClose={()=>{
                    setUploadDialog("");
                }}
            />
            <UploadStatusDialog 
                title={'job description'}
                documentType={'job-descriptions'} 
                active={uploadDialog === 'job description'} 
                onExpand={()=>{setUploadDialog('job description')}} 
                onClose={()=>{
                    setUploadDialog("");
                }}
            />
        </div>
        {
            isMobile ? 
            // Mobile View
            <div className="dashboard-container-mobile">
                <Drawer
                    open={drawerOpen}
                    onClose={()=>setDrawerOpen(false)}
                    anchor="left"
                >
                    <div className="dashboard-mobile-drawer-container">
                        <AppLogo color="white" className={"dashboard-logo-mobile"} />
                        {
                            MENU_ITEMS.map((item, index)=>(
                                (!item.autorization || (user.role === 'superadmin')) ?
                                <Link key={index} to={item.path} style={{textDecoration:'none'}}
                                    id={item.id} 
                                    className={"menu-item-mobile" + (item.path === location.pathname ? " selected" : "")}
                                    onClick={()=>{
                                        setMenu(item.name);
                                        setDrawerOpen(false);
                                    }}    
                                >
                                    <div className="menu-item-icon mobile"><item.icon color={item.path === location.pathname ? "#EA723C" : 'white'}/></div>
                                    <div className="menu-item-content">{item.name}</div>
                                </Link>
                                : null
                                )  
                            )
                        }
                        <div
                            className="menu-item-mobile"
                            onClick={handleLogout}
                            style={{
                                position:"absolute",
                                bottom:"2rem"
                            }}
                        >
                            <div className="menu-item-icon mobile logout-btn-mobile"><LogoutIcon color={'#EA723C'} /></div>
                            <div className="menu-item-content">Logout</div>
                        </div>
                        <div
                            style={{
                                color:"white",
                                position:"absolute",
                                bottom:"0.75rem",
                                left:"1.1rem"
                            }}
                        >{APP_VERSION}</div>
                    </div>
                </Drawer>
                <div className="sticky-header-mobile">
                    <div className="dashboard-header-container-mobile">
                        <IconButton onClick={()=>setDrawerOpen(true)}>
                            <HamburgerButtonIcon color="#EA723C" margin="0" padding="0" />
                        </IconButton>
                        <div className="dashboard-box-header-user">
                            <div className="dahsboard-box-header-user-name" style={{fontSize:"1rem"}}>Hi, {user.firstname} {user.lastname}</div>
                            <div className="dahsboard-box-header-user-role" style={{fontSize:"0.8rem"}}>{user.role}</div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-mobile-box">
                    <CustomBreadcrumbs />    
                    <Outlet />
                </div>
            </div> 
            
            : // ----------------------------------------------------------------------------------------
            
            // Desktop View
            <div className="dashboard-container">
                {
                    tour ? <SiteTour tourSteps={TOUR_STEPS} onEnd={()=>{
                        setTour(false);
                        navigate("/");
                    }} /> : null
                }
                <div className="dashboard-overlay"></div>
                <div className="dashboard-drawer">
                    <AppLogo className="dashboard-drawer-logo" />
                    <p
                        style={{
                            position:"relative",
                            left:"10%",
                            width:"100%",
                            height:"1px",
                            background:"#B5B5B5",
                        }}
                    ></p>
                    <div className="dashboard-drawer-core">
                        {
                            MENU_ITEMS.map((item, index)=>(
                                (!item.autorization || (user.role === 'superadmin')) ?
                                <Link key={index} to={item.path} style={{textDecoration:'none'}}
                                    id={item.id} 
                                    className={"menu-item" + (item.path === location.pathname ? " selected" : "")}
                                    onClick={()=>{
                                        setMenu(item.name);
                                    }}    
                                >
                                    <Paper className={"menu-item-inner-container" + (item.path === location.pathname ? " selected" : "")}
                                        elevation={0}
                                        sx={{
                                            background : item.path === location.pathname ? "#EA723C" : "#F8F9FA",
                                            zIndex : item.path === location.pathname ? 5 : 0,
                                            padding:"0.5rem",
                                            borderRadius:"16px",
                                            fontWeight:600,
                                            color:item.path === location.pathname ? "white" : "#1E1E1E",
                                            fontSize:"1rem",
                                        }}
                                    >
                                        <div className="menu-item-icon"
                                            style={{
                                                height:"2rem",
                                                width:"2rem",
                                                background:item.path === location.pathname ? "white" : "white",
                                                borderRadius:"10px",
                                                display:"flex",
                                                justifyContent:"center",
                                                alignItems:"center",
                                            }}
                                        ><item.icon color={item.path === location.pathname ? "black" : "#000000"} width={"50%"} /></div>
                                        <div className="menu-item-content">{item.name}</div>
                                    </Paper>
                                </Link>
                                : null
                                )
                            )
                        }

                    </div>
                    <div className="menu-item bottom-item"
                        onClick={handleLogout}
                    >
                        <div className="menu-item-icon"
                            style={{
                                height:"2rem",
                                width:"2rem",
                                borderRadius:"10px",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                background:"#EA723CD3"            
                            }}
                        ><LogoutIcon color={'white'} width={"50%"} /></div>
                        <div className="menu-item-content"
                            style={{
                                fontWeight:600,
                                color:"#1E1E1E",
                                fontSize:"1rem"
                            }}
                        >Logout</div>
                    </div>
                    <div className="version-info">{APP_VERSION}</div>
                </div>

                <div className="dashboard-box">
                    <div className="dashboard-box-header">
                        <div aria-hidden></div>
                        <div className="dashboard-box-header-right"
                            style={{
                                display:"flex",
                            }}
                        >
                            <Fade in={menu === 'Home'}>
                                <Button 
                                    variant="contained" 
                                    style={{
                                        textTransform:'none', 
                                        color:"white",
                                        borderRadius:"12px",
                                        fontWeight:"600",
                                        fontFamily:"montserrat", 
                                        fontSize:"0.9rem",
                                        background:"#EA723C",
                                        marginRight:"2rem",
                                        height:"80%"
                                    }}
                                    onClick={()=>setTour(true)}
                                >Take a Tour</Button>
                            </Fade>
                            <div className="dashboard-box-header-user">
                                <div className="dahsboard-box-header-user-name">Hi, {user.firstname} {user.lastname}</div>
                                <div className="dahsboard-box-header-user-role">{user.role}</div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-box-content">
                        <CustomBreadcrumbs />
                        <Outlet />
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Dashboard;