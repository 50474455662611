import { CrossCircleIcon, UploadIcon } from "@icons/DialogsIcons";
import styles from "./FileDropInput.module.css";
import React, { useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ResumesIcon } from "@icons/DashboardIcons";
import { PlusIcon } from "@icons/ActionsIcons";
import { errors } from "@constants/uploadDialogEnums";
import useResponsiveView from "@hooks/useResponsiveView";
import { ALLOWED_TYPES, MAX_ALLOWED_SIZE } from "@constants/uploadConstants";

const FileDropInput = ({className="", defaultValue, onChange=()=>{}, multiple=true, id="fileDropInput", maxFileSize=MAX_ALLOWED_SIZE, allowedTypes=ALLOWED_TYPES, error=false, helperText=""}) => {
    const [value, setValue] = useState(defaultValue);
    const [failed, setFailed] = useState([]);
    const isMobile = useResponsiveView();

    const handleFilesUpdate = useCallback((files) => {
        let failedFiles = [];
        let validFiles = [];

        for(let file of files){
            const extention = file.name?.split(".").pop().toLowerCase();
            if(allowedTypes && !allowedTypes.includes(`.${extention}`)){
                failedFiles.push({
                    file,
                    reason : errors.TYPE_INVALID
                });
                continue;
            }

            if(maxFileSize && (file.size > maxFileSize)){
                failedFiles.push({
                    file,
                    reason : errors.FILESIZE_EXCEEDED
                })
                continue;
            }

            validFiles.push(file);
        }

        setFailed(prev=>[...prev, ...failedFiles]);
        setValue(prev=>{
            if(prev){
                return [...prev, ...validFiles];
            }else{
                return validFiles;
            }
        })
    }, [])
    
    const handleFileDrop = useCallback((inputFiles) => {
        handleFilesUpdate(inputFiles);
    }, [])
    
    const { getRootProps, isDragActive } = useDropzone({onDrop:handleFileDrop})

    const handleValueChange = (event) => {
        if(!event.target?.files) return;
        handleFilesUpdate(event.target.files);
    }

    const handleRemoveFile = (file) => {
        setValue(prev=>prev?.filter(item => item !== file));
        document.getElementById(id).value = "";
    }

    useEffect(()=>{
        onChange(value);
    }, [value])

    return (
        <div className={styles.container + " " + (isDragActive && styles.active) + " " + className} {...getRootProps()} >
            <input onChange={handleValueChange} id={id} type="file" multiple={multiple} />
            {
                (value?.length) ?
                <div className={styles.previewContainer}>
                    <div className={styles.filesContainer}>
                        {Array.from(value || [])?.map((file, idx)=>(
                            <div key={idx} className={styles.filePreview}>
                                <ResumesIcon height="40%" width="40%" />
                                <div className={styles.filename}>
                                    {file.name}
                                </div>
                                <div className={styles.filesize}>
                                    {(file.size / (1024)).toFixed(1)}KB
                                </div>

                                <IconButton 
                                sx={{
                                    position : "absolute",
                                    top : "-5px",
                                    right : "-5px",
                                    padding : "0px",
                                    height : "fit-content",
                                    background:"white"
                                }} 
                                onClick={(e)=>{e.preventDefault();handleRemoveFile(file)}}>
                                    <CrossCircleIcon width="18px" height="18px" />
                                </IconButton>
                            </div>
                        ))}
                        {
                        multiple &&
                        <label className={styles.addMoreContainer} htmlFor={id}>
                            <PlusIcon color="gray" width="23px" height="23px" />
                        </label>
                        }
                    </div>

                    {
                        failed.length ? (
                            <div className={styles.failedContainer}>
                                <div className={styles.failedHeader}>Invalid files - will not be uploaded</div>
                                <div className={styles.filesContainer}>
                                    {
                                        failed.map((item, idx)=>(
                                            <div key={idx} className={styles.filePreview + " " + styles.failedPreview}>
                                                <ResumesIcon height="40%" width="40%" />
                                                <div className={styles.filename}>
                                                    {item.file.name}
                                                </div>
                                                <div className={styles.filesize}>
                                                    {item.reason}
                                                </div>

                                                <IconButton 
                                                sx={{
                                                    position : "absolute",
                                                    top : "-5px",
                                                    right : "-5px",
                                                    padding : "0px",
                                                    height : "fit-content",
                                                    background:"white"
                                                }} 
                                                onClick={(e)=>{e.preventDefault();setFailed(prev=>prev.filter(subItem=>subItem!==item))}}>
                                                    <CrossCircleIcon width="18px" height="18px" />
                                                </IconButton>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : null
                    }            
                    
                </div>
                :
                <label className={styles.innerContainer} htmlFor={id}>
                    <div className={styles.uploadIconContainer}>
                        <UploadIcon className={styles.uploadIcon}/>
                    </div>
                    <div className={styles.uploadText}>
                        {
                            isDragActive ?
                            <>
                                <div><b>Drop Here</b></div>
                            </>
                            :
                            <>
                                {
                                    isMobile ? null : 
                                    <>
                                        <div><b>Drag & Drop</b></div>
                                        <p>or</p>
                                    </>
                                }
                                <label htmlFor={id} className={styles.uploadButton}>Click to Upload</label>
                            </>
                        }
                    </div>
                    <div className={styles.helperText}><b>Supported Formats</b> - txt, docx and pdf ; Max Size - 20MB</div>
                    {error && <p className={styles.errorText}>{helperText}</p>}
                    {!!failed.length && <p className={styles.errorText}>Files selected either is invalid types or exceeds max size</p>}
                </label>
            }
                
        </div>
    )
}

export default FileDropInput;