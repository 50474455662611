import { CustomAutoComplete } from "@components/CustomMUIInputs";
import { useDocuments } from "@contexts/DocumentsContext";
import { CrossCircleIcon } from "@icons/DialogsIcons";
import { Replay } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, List, ListItem, Radio, RadioGroup } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const ResumesFilterDrawer = ({open, onClose, onApplyFilters=()=>{}, defaultSource="", defaultTarget=null}) => {
    const [source, setSource] = useState(defaultSource || "");
    const [target, setTarget] = useState(defaultTarget || null);
    const { documents} = useDocuments("job-descriptions");
    
    const targetOptions = useMemo(()=>{
        return documents.map(item => item._id);
    }, [documents])
    const targetLabelMap = useMemo(()=>{
        return documents.reduce((acc, item) => {
            acc[item._id] = item.filename;
            return acc;
        }, {});
    }, [documents])

    useEffect(()=>{
        setSource(defaultSource);
        setTarget(defaultTarget || null);
    }, [defaultSource, defaultTarget])

    const handleApplyFilters = () => {
        onApplyFilters({
            source, target:target || ""
        });
    }

    const handleResetFilters = () => {
        setSource("");
        setTarget(null);
    }

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box 
                width={"20rem"} 
                height={"100%"} 
                padding={"0rem 1.5rem 1rem"} 
                maxWidth={"65vw"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
            >
                <List>
                    <ListItem style={{display:"flex", justifyContent:"space-between"}}>
                        <div 
                            style={{
                               fontSize:"1.3rem",
                               fontWeight:"700" 
                            }}
                        >Filters</div>
                        <div>
                            <IconButton onClick={handleResetFilters}>
                                <Replay color="gray" width="1.5rem" height="1.5rem" />
                            </IconButton>
                            <IconButton onClick={onClose}>
                                <CrossCircleIcon width="1.5rem" height="1.5rem" />
                            </IconButton>
                        </div>
                    </ListItem>
                    <Divider />
                    <ListItem style={{margin:"1rem 0rem"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"
                                style={{
                                    fontSize:"1.1rem",
                                    fontWeight:"600",
                                    color:"black",
                                    margin:"0.5rem 0rem"
                                }}
                            >By Source</FormLabel>
                            <RadioGroup
                                value={source}
                                onChange={(e)=>setSource(e.target.value)}
                            >
                                <FormControlLabel value="" control={<Radio size="small" color="warning" />} label="All" />
                                <FormControlLabel value="via_link" control={<Radio size="small" color="warning" />} label="Via Link" />
                                <FormControlLabel value="manual" control={<Radio size="small" color="warning" />} label="Manual" />
                            </RadioGroup>
                        </FormControl>
                    </ListItem>

                    <ListItem style={{flexDirection:"column", alignItems:"flex-start"}}>
                        <label
                            style={{
                                fontSize:"1.1rem",
                                fontWeight:"600",
                                color:"black",
                                margin:"0.5rem 0rem"
                            }}
                        >
                            By Target
                        </label>
                        <CustomAutoComplete 
                            options={targetOptions}
                            placeholder={"Select Target JD"}
                            getOptionKey={(documentID)=>documentID}
                            getOptionLabel={(documentID)=>targetLabelMap[documentID] || ''}
                            sx={{ 
                                width: "100%", padding : "0rem 0.2rem", boxSizing : "border-box",
                                "& .MuiInputBase-root" : {
                                    height : "2.8rem",
                                }
                            }}
                            value={target}
                            onChange={(event, newValue) => setTarget(newValue)}
                        />
                    </ListItem>
                </List>
                <div
                    style={{
                        display:"flex"
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            color:"black",
                            borderColor:"gray",
                            "&:hover" : {
                                borderColor:"black"
                            }
                        }}
                        style={{
                            background:"transparent",
                            textTransform:"none",
                            width:"100%",
                            height:"2.5rem",
                            margin:"0.5rem",
                            borderRadius:"12px",
                            fontWeight:"600",
                        }} 
                    >Cancel</Button>
                    <Button
                        onClick={handleApplyFilters}
                        variant="contained"
                        style={{
                            background:"#EA723C",
                            textTransform:"none",
                            width:"100%",
                            height:"2.5rem",
                            margin:"0.5rem",
                            borderRadius:"12px",
                            fontWeight:"600",
                        }} 
                    >Apply</Button>
                </div>
            </Box>
        </Drawer>
    )
}

export default ResumesFilterDrawer;