import { BarChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { IntervalPicker, CustomDatePicker, WeekPicker, customDatePickerTheme } from "@components/CustomDatePicker/CustomDatePicker";
import { ThemeProvider } from "@mui/material";

const TimeSeriesGraph = ({dataset, xAxis={dataKey:"created_at"}, yAxis=[], series=[], getSeries = (item) => {}}) => {
    const [loading, setLoading] = useState(true);
    const [interval, setInterval] = useState("weekly");
    const [startTime, setStartTime] = useState(dayjs());
    const [graphData, setGraphData] = useState([]);

    useEffect(()=>{
        setLoading(true);
        const today = startTime || dayjs();
        const start = interval === "weekly" ?
            today.startOf("week")
            :
            today.startOf("year")
        const end = interval === "weekly" ?
            today.endOf("week")
            :
            today.endOf("year")

        
        const counts = {};
        let currentDate = start.clone();
        let isData = false;
        
        while (currentDate <= end){
            counts[currentDate.toISOString()] = {}
            series.forEach(item => (
                counts[currentDate.toISOString()][item.dataKey] = 0
            ))
            currentDate = currentDate.add(1, interval === "weekly" ? 'day' : "month");
        }
        
        dataset?.forEach(data => {
            const time = dayjs(data?.[xAxis.dataKey]);
            if(!time || !(start < time && time < end)) return;
            isData = true;
            counts[time.startOf(interval === "weekly" ? "day" : "month").toISOString()][getSeries(data)] += 1;
        })
        setGraphData(isData ? Object.entries(counts).map(([key, value])=>({created_at : key, ...value})) : []);
        setLoading(false);
    }, [interval, startTime])

    return (
    <>
    <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
        <ThemeProvider theme={customDatePickerTheme}>
            <CustomDatePicker 
                inputType={interval === "weekly" ? "week" : "year"} 
                value={startTime}
                onChange={setStartTime}
                sx={{
                    margin:"0rem 0.5rem"
                }}
            />
            <IntervalPicker value={interval} onChange={(e)=>setInterval(e.target.value)} />
        </ThemeProvider>
    </div>
    <div style={{width:"100%", height:"calc(100% - 2rem)"}}>
    <BarChart
        borderRadius={6}
        dataset={graphData}
        loading={loading}
        grid={{horizontal:true, vertical:false}}
        xAxis={[
            {
                scaleType: 'band',
                dataKey: xAxis.dataKey,
                label: 'Time Duration',
                valueFormatter:(value, context) => {
                    if(context.location === "tick") 
                        return interval === "weekly" ? dayjs(value).format("ddd") : dayjs(value).format("MMM");
                    return interval === "weekly" ? dayjs(value).format("DD-MM ddd") : dayjs(value).format("MMMM YY")
                }
            },
        ]}
        yAxis={yAxis}
        // series={series}
        series={series}
        slotProps={{
            legend: {
                direction: 'row',
                labelStyle:{
                    fontSize:"0.75rem",
                    fontWeight:"500",
                    fontFamily:"inherit",
                    // fill:"green"
                },
                itemMarkHeight:10,
                itemMarkWidth:10,
            },
            axisLabel:{
                style:{
                    fontSize:"0.95rem",
                    fontWeight:"500",
                    fontFamily:"inherit",
                }
            },
            axisTickLabel: {
                style:{
                    fontSize:"0.75rem",
                    fontWeight:"400",
                    fontFamily:"inherit"
                }
            },
        }}
    />
    </div>
    </>
)}

export default TimeSeriesGraph;