export const RESET_USER_PASSWORD_FORM = {
    title : "Reset User Password",
    defaultOptions : {required:true},
    inputFields : [
        {type:"select", id:"user", name:"user", label:"User", placeholder:"Select User", options:[], menuItemStyle:{textTransform:"none"} },
        {type:'text-field', id:"password", name:"password", label:"Password"}
    ],
    actionButtons : [
        {type:"submit", name:"Reset"}
    ]
}

export const ADD_USER_FORM = {
    title : "Add User",
    defaultOptions: {required:true, defaultValue:""},
    inputFields : [
        {type:'text-field', name:"firstname", placeholder:"Enter First Name", label:"First Name"},
        {type:'text-field', name:"lastname", placeholder:"Enter Last Name", label:"Last Name"},
        {type:'text-field', inputType:"email", name:"email", placeholder:"Enter Email", label:"Email Address"},
        {type:'text-field', name:"password", placeholder:"Enter Password", label:"Password"},
        {type:'select', name:"role", label:"Role", placeholder:"Select Role", style:{textTransform:"capitalize"}}
    ],
    actionButtons : [
        {type:"submit", name:"Add"}
    ]
}

export const EDIT_USER_FORM = {
    title : "Edit User",
    inputFields : [
        {type:'text-field', name:"username", placeholder:"Enter Email", label:"Email Address", disabled:true},
        {type:'text-field', name:"firstname", placeholder:"Enter First Name", label:"First Name"},
        {type:'text-field', name:"lastname", placeholder:"Enter Last Name", label:"Last Name"},
        {type:'select', name:"role", label:"Role", placeholder:"Select Role", style:{textTransform:"capitalize"}}
    ],
    actionButtons : [
        {type:"submit", name:"Edit"}
    ]
}

export const RENAME_DOCUMENT = {
    title : "Rename Document",
    inputFields : [
        {type:'text-field', name:"filename"},
    ],
    actionButtons : [
        {type:"submit", name:"Rename"}
    ]
}