const documentStatusRenderMap = {
    'new' : {
        text : "New",
        background : '#DDF2F6',
        color : '#0074B3',
        documentType : "resumes"
    },
    'under_review' : {
        text : "Under Review",
        background : "#FFE5B6",
        color : "#DF9C00",
        documentType : "resumes"
    },
    'shortlisted' : {
        text : "Shortlisted",
        background : "#FFEDE4",
        color : "#EA723C",
        documentType : "resumes"
    },
    'interview_scheduled' : {
        text : "Interview Scheduled",
        background : "#FFD3F5",
        color : "#ED00C9",
        documentType : "resumes"
    },
    'selected' : {
        text : "Selected",
        background : "#BEFFD4",
        color : "#098000",
        documentType : "resumes"
    },
    'rejected' : {
        text : "Rejected",
        background : "#FFE2E3",
        color : "#FF5E62",
        documentType : "resumes"
    },
    'open' : {
        text : "Open",
        background : '#DDF2F6',
        color : '#0074B3',
        documentType : "job-descriptions"
    },
    'on_hold' : {
        text : "On Hold",
        background : '#FFF1D6',
        color : '#B86F01',
        documentType : "job-descriptions"
    },
    'closed' : {
        text : "Closed",
        background : "#BEFFD4",
        color : "#098000",
        documentType : "job-descriptions"
    }
}

const getDocumentStatusList = (documentType) => (
    Object.entries(documentStatusRenderMap).filter(([k, v]) => (v.documentType === documentType))
)

export { documentStatusRenderMap, getDocumentStatusList };