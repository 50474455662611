import { ArchiveIcon, DeleteIcon, EditDocumentIcon } from "@icons/ActionsIcons";
import { Tooltip, IconButton } from "@mui/material";

const ActionsRenderer = ({data:document, onAction=(document, action)=>{}, actionList=["rename", "archive", "delete"], ...params})=>{

    return (
        <>
            {
                actionList.includes("rename") &&
                <Tooltip title="Rename" arrow>
                    <IconButton className="action-btn" onClick={()=>onAction(document, 'rename')}>
                        <EditDocumentIcon className="action-svg-icon" />        
                    </IconButton>
                </Tooltip>
            }
            
            {
                actionList.includes("archive") &&
                <Tooltip title="Archive" arrow>
                    <IconButton className="action-btn" onClick={()=>onAction(document, 'archive')}>
                        <ArchiveIcon className="action-svg-icon" />        
                    </IconButton>
                </Tooltip>
            }

            {
                actionList.includes("delete") && (
                params.disabled ? 
                <IconButton disabled={params.disabled}>
                    <DeleteIcon color={"lightgray"} />        
                </IconButton>
                :
                <Tooltip title="Delete" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            onAction(document, 'delete');
                        }}
                    >
                        <DeleteIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )
}

export default ActionsRenderer;
