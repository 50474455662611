import React from 'react';
import ReactDOM from 'react-dom/client';
import '@styles/index.css';

import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DocumentsProvider } from '@contexts/DocumentsContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from './pages';
import { pageErrors } from '@constants/errorPageInfo';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ErrorBoundary fallbackRender={(error)=><ErrorPage error={{...pageErrors.SYSTEM, errorDescription:error?.message}} />}>
          <AuthProvider>
            <DocumentsProvider>
              <App />
            </DocumentsProvider>
          </AuthProvider>
        </ErrorBoundary>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);
