import { Tooltip } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

const FilenameRenderer = ({value, data:document, ...params}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Tooltip title={params.value} open={isOpen} onClick={()=>setIsOpen(true)} onClose={()=>setIsOpen(false)}
                style={{
                    textOverflow:"ellipsis",
                    overflowX:"clip",
                }}
            >
                <div>
                    {value}
                </div>
            </Tooltip>
        </div>
    )
}

export default FilenameRenderer;