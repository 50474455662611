const NameRenderer = ({data:user})=>{
    return (
        <div
            style={{
                fontSize : "1rem",
                padding:"0",
            }}
        >
            <div style={{height:"25px", fontWeight:"500", overflowX:"clip", textOverflow:"ellipsis"}}>{user.firstname} {user.lastname}</div>
            <div
                style={{
                    color:"gray",
                    overflowX:"clip",
                    textOverflow:"ellipsis"
                }}
            >{user.username}</div>
        </div>
    )
}

export default NameRenderer;