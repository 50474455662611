const InterviewScheduledIcon = ({color="#1E1E1E", ...params}) => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 10.6001V17.0001L19.4 19.4001" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.0726 24.1255C31.6908 25.5393 32.9999 26.2463 32.9999 27.4C32.9999 28.5538 31.6908 29.2608 29.0726 30.6746L27.2897 31.6374C25.279 32.7233 24.2736 33.2662 23.7902 32.8715C22.6063 31.9047 24.4507 29.4099 24.9044 28.526C25.3643 27.6304 25.3559 27.1535 24.9044 26.2741C24.4507 25.3902 22.6063 22.8954 23.7902 21.9286C24.2736 21.5339 25.279 22.0768 27.2897 23.1627L29.0726 24.1255Z" stroke={color} strokeWidth="1.5"/>
        <path d="M18.6418 32.9168C18.102 32.9718 17.5543 33 17 33C8.16344 33 1 25.8366 1 17C1 8.16344 8.16344 1 17 1C25.8366 1 33 8.16344 33 17C33 18.0959 32.8898 19.166 32.6799 20.2" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default InterviewScheduledIcon;