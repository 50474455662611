import { ArchiveIcon } from "@icons/ActionsIcons";
import {
    HomeIcon, UsersIcon, ResumesIcon, JobDescriptionsIcon, RankAndAnalyzeIcon, FAQsIcon
} from "@icons/DashboardIcons";

const MENU_ITEMS = [
    {
        name : "Home",
        icon : HomeIcon,
        path : '/',
        id : "homePageLink"
    },
    {
        name : "Users",
        icon : UsersIcon,
        path : '/users',
        autorization : true,
        id : "usersPageLink"
    },
    {
        name : "Resumes",
        icon : ResumesIcon,
        path: '/resumes',
        id : "resumesPageLink"
    },
    {
        name : "Job Descriptions",
        icon : JobDescriptionsIcon,
        path : '/job-descriptions',
        id : "jobDescriptionsPageLink"
    },
    {
        name : "Rank & Analyze",
        icon : RankAndAnalyzeIcon,
        path : '/rank-and-analyze',
        id : "rankAndAnalyzePageLink"
    },
    {
        name : "Help & Support",
        icon : FAQsIcon,
        path : '/help-and-support',
        id : "helpAndSupportLink"
    },
    {
        name : "Archive",
        icon : ArchiveIcon,
        path : '/archive',
        id : "archiveLink"
    }
]

export default MENU_ITEMS; 