export const usersDialogTypes = {
    ADD_USER : 'addUser',
    EDIT_USER : 'editUser',
    DELETE_USER : 'deleteUser',
    RESET_PASSWORD : 'resetPassword',
    NONE : ''
}

export const documentsDialogTypes = {
    DELETE_DOCUMENT : 'deleteDocument',
    ARCHIVE_DOCUMENT : 'archiveDocument',
    DELETE_MULTI : 'deleteMutli',
    RENAME_DOCUMENT : 'renameDocument',
    UPLOAD_DOCUMENT : "uploadDocument",
    PREVIEW_DOCUMENT : 'previewDocument',
    NONE : ''
}