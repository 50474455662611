const FilterIcon = ({color="#1E1E1E", ...props}) => {
    return (
        <svg width="60" height="60" {...props} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" rx="12" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.332 18C22.5957 18 21.9987 18.597 21.9987 19.3333V20.8943C20.4451 21.4434 19.332 22.925 19.332 24.6667C19.332 26.4083 20.4451 27.89 21.9987 28.4391V40.6667C21.9987 41.4031 22.5957 42 23.332 42C24.0684 42 24.6654 41.4031 24.6654 40.6667V28.4391C26.219 27.89 27.332 26.4083 27.332 24.6667C27.332 22.925 26.219 21.4434 24.6654 20.8943V19.3333C24.6654 18.597 24.0684 18 23.332 18ZM24.6654 24.6667C24.6654 25.4031 24.0684 26 23.332 26C22.5957 26 21.9987 25.4031 21.9987 24.6667C21.9987 23.9303 22.5957 23.3333 23.332 23.3333C24.0684 23.3333 24.6654 23.9303 24.6654 24.6667Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.668 18C35.9316 18 35.3346 18.597 35.3346 19.3333V30.2276C33.781 30.7767 32.668 32.2584 32.668 34C32.668 35.7416 33.781 37.2233 35.3346 37.7724V40.6667C35.3346 41.4031 35.9316 42 36.668 42C37.4044 42 38.0013 41.4031 38.0013 40.6667V37.7724C39.5549 37.2233 40.668 35.7416 40.668 34C40.668 32.2584 39.5549 30.7767 38.0013 30.2276V19.3333C38.0013 18.597 37.4044 18 36.668 18ZM38.0013 34C38.0013 34.7364 37.4044 35.3333 36.668 35.3333C35.9316 35.3333 35.3346 34.7364 35.3346 34C35.3346 33.2636 35.9316 32.6667 36.668 32.6667C37.4044 32.6667 38.0013 33.2636 38.0013 34Z" fill="white"/>
        </svg>
    )
}

export default FilterIcon;