const Suggestion = ({color="#EA723C", ...params}) => {
    return (
        <svg width={"32"} height={"32"} {...params} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="63" height="63" rx="15.5" fill={"transparent"} fillOpacity="0.1" stroke={color}/>
        <path d="M31.8235 14.353C33.8487 23.7841 41.2159 31.1513 50.6471 33.1765C41.2159 35.2017 33.8487 42.5689 31.8235 52C29.7983 42.5689 22.4311 35.2017 13 33.1765C22.4311 31.1513 29.7983 23.7841 31.8235 14.353Z" fill={color}/>
        <path d="M42.4123 12C43.1718 15.5367 45.9345 18.2994 49.4712 19.0588C45.9345 19.8183 43.1718 22.581 42.4123 26.1176C41.6529 22.581 38.8902 19.8183 35.3535 19.0588C38.8902 18.2994 41.6529 15.5367 42.4123 12Z" fill={color}/>
        </svg>
    )
}

export default Suggestion;