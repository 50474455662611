import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import "./jd-page.css";
import { useDocuments } from "@contexts/DocumentsContext";
import React, { useEffect, useMemo, useState } from "react";
import { DocumentPreview, LoadingIndicator, QuickLinkCard, TimeSeriesGraph } from "@components/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FilenameRenderer, PreviewRenderer, StatusRenderer } from "@renderers/documents-renderers";
import { MoreVert } from "@mui/icons-material";
import styled from "@emotion/styled";
import Lottie from "react-lottie";
import deleteAnimation from "@animations/deleteAnimation.json"
import { ArchiveIcon, CopyLinkIcon, DeleteIcon } from "@icons/ActionsIcons";
import { documentsDialogTypes as dDialogTypes } from "@constants/dialogsEnums";
import { RankAndAnalyzeIcon } from "@icons/DashboardIcons";
import { InterviewScheduledIcon, NewResumesIcon, ShortlistedIcon } from "@icons/JDPageIcons";
import { CustomSwitch } from "@components/CustomMUIInputs";
import ConfirmDialog from "@components/ConfirmDialog";
import { toast } from "react-toastify";
import ResponsiveWrapper from "@components/ResponsiveWrapper";
import PreviewIcon from "@icons/ActionsIcons/PreviewIcon";

const CustomizedMenuItem = styled(MenuItem)({
    transition:"background-color ease-in-out 300ms",
    "&:hover" : {
        background:"#EA723C33"
    }
})

const JDPage = () => {
    const { documents, isLoading, updateDocumentStatus, mutateUpdateDocument, mutateDeleteDocument, } = useDocuments();
    const [anchorEl, setAnchoroEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const location = useLocation();
    const navigate = useNavigate();
    const [graphData, setGraphData] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [openDialog, setOpenDialog] = useState(dDialogTypes.NONE);

    const document = useMemo(()=>{
        const pathSegments = location.pathname.split("/");
        const id = pathSegments[pathSegments.length - 1];
        return documents.find(item => item._id === id) || {};
    }, [documents]);

    const listResumes = useMemo(()=>{
        if(!document) return [];
        return documents.filter(item => (item.document_type === "resumes" && item.target === document._id))
    }, [document, documents]);

    const resumeCounts = useMemo(()=>{
        return {
            new : listResumes.filter(item => item.status === "new").length,
            shortlisted : listResumes.filter(item => item.status === "shortlisted").length,
            interview_scheduled : listResumes.filter(item => item.status === "interview_scheduled").length
        }
    }, [listResumes])

    const handleChangeLinkActive = async (event, newValue) => {
        try {
            await mutateUpdateDocument({data:[document?._id, {is_link_active : newValue}]});
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }
    }

    const handlePreviewDocument = (document) => {
        setSelectedDocument(document);
        setOpenDialog(dDialogTypes.PREVIEW_DOCUMENT);
    }

    const handleStatusUpdate = async (documentId, actionItem) => {
        try {
            await updateDocumentStatus(documentId, actionItem);
            if(actionItem === "archived"){
                toast.success("Job archived successfully", {closeOnClick:true})
                navigate("/");
            }
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }
    }

    const handleDeleteDocument = async (documentID) => {
        try{
            await mutateDeleteDocument({data:documentID});
            toast.success("Job deleted successfully", {closeOnClick:true})
            navigate("/");
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }
    }

    const handleCopyWebCareersLink = ()=>{
        const baseURL =  process.env.REACT_APP_PUBLIC_URL || window.location.origin;
        const webCareersURL = `${baseURL}/web-careers/${document?._id}`;
        try{
            navigator?.clipboard?.writeText(webCareersURL);
            toast.success("Copied to clipboard", {closeOnClick:true});
        }catch(error){
            console.log("Could not copy to clipboard");
            alert("Could not copy to clipboard, please copy manyally : " + webCareersURL);
        }
    }

    useEffect(()=>{
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 7);
        startDate.setHours(0, 0, 0, 0);

        const counts = {}
        listResumes.forEach(item => {
            const createdAt = new Date(item.created_at);
            if(createdAt < startDate || createdAt > today) 
                return;
            
            createdAt.setHours(0, 0, 0, 0);
            const source = item.source ? "manual" : "via_link";

            if(!counts[createdAt]){
                counts[createdAt] = {manual:0, via_link:0};
            }

            counts[createdAt][source] += 1;
        })
        const data = [];
        for(let i=0; i<7; i++){
            startDate.setDate(startDate.getDate() + 1);
            let currDay = startDate;
            data.push({
                date : new Date(currDay),
                via_link : counts[currDay]?.via_link || 0,
                manual : counts[currDay]?.manual || 0
            })
        }
        setGraphData(data);
    }, [documents])

    return (
        isLoading ? <LoadingIndicator /> :
        <>
        <ConfirmDialog
            open={openDialog === dDialogTypes.DELETE_DOCUMENT} title="Remove Document"
            icon={
                <Lottie
                    options={{
                        loop : true,
                        autoplay : true,
                        animationData : deleteAnimation,
                        renderer : "svg"
                    }}
                    height={"10rem"}
                    width={"50%"}
                />
            }
            content={`Are you sure you want to delete the document ${document?.filename}?`}
            onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
            onConfirm={()=>{
                handleDeleteDocument(document?._id);
                setOpenDialog(dDialogTypes.NONE);
            }}
        />
        
        <ConfirmDialog
            open={openDialog === dDialogTypes.ARCHIVE_DOCUMENT} title="Archive Document"
            icon={
                <ArchiveIcon width="5rem" height="5rem" />
            }
            content={`Are you sure you want to archive the document ${document?.filename}?`}
            onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
            onConfirm={()=>{
                handleStatusUpdate(document?._id, "archived");
                setOpenDialog(dDialogTypes.NONE);
            }}
        />

        <DocumentPreview 
            open={openDialog === dDialogTypes.PREVIEW_DOCUMENT}
            document={selectedDocument}
            onClose={()=>setOpenDialog(dDialogTypes.NONE)}
        />
        <div className="jd-page-container">
            <ResponsiveWrapper>
                <div className="jd-header">
                    <div className="jd-header-title">{document?.job_title || document?.filename}</div>
                    <div className="jd-header-right-side">
                        <StatusRenderer value={document?.status} data={document} onUpdate={handleStatusUpdate} />
                        <div className="jd-header-more-button">
                            <IconButton
                                id="jdPageMoreActions"
                                onClick={(e)=>setAnchoroEl(e.currentTarget)}
                                sx={{
                                    borderRadius:"8px",
                                    boxShadow:"1px 1px 3px -1px #0000003f",
                                    height:"2rem",
                                    width:"2rem",
                                    fontWeight:"700",
                                    color:"#000000",
                                    transition:"color ease-in-out 300ms, background-color ease-in-out 300ms",
                                    "&:hover" : {
                                        background:"#EA723C",
                                        color:"white",
                                    }
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="jdPageMoreMenu"
                                aria-labelledby="jdPageMoreActions"
                                open={menuOpen}
                                anchorEl={anchorEl}
                                onClose={()=>setAnchoroEl(null)}
                            >
                                <CustomizedMenuItem onClick={()=>{
                                    setSelectedDocument(document);
                                    setOpenDialog(dDialogTypes.PREVIEW_DOCUMENT);
                                }}>
                                    <ListItemIcon><PreviewIcon color="black" width="25" height="25" /></ListItemIcon>
                                    <ListItemText>Preview</ListItemText>
                                </CustomizedMenuItem>
                                <CustomizedMenuItem onClick={()=>{
                                    setOpenDialog(dDialogTypes.DELETE_DOCUMENT);
                                }}>
                                    <ListItemIcon><DeleteIcon color="black" width="25" height="25" /></ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                </CustomizedMenuItem>
                                <CustomizedMenuItem onClick={()=>{
                                    setOpenDialog(dDialogTypes.ARCHIVE_DOCUMENT);
                                }}>
                                    <ListItemIcon><ArchiveIcon color="black" width="25" height="25" /></ListItemIcon>
                                    <ListItemText>Archive</ListItemText>
                                </CustomizedMenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="jd-page-content-box">
                    <div className="jd-page-content-top">
                        <div className="graph-box">
                        <TimeSeriesGraph 
                            dataset={listResumes} 
                            yAxis={[    
                                {
                                    label: 'Resume Uploads',                                            
                                },
                            ]}
                            series={[
                                {
                                    label:"Via Link",
                                    dataKey : "via_link",
                                    color:"#6F00FF4D",
                                },
                                {
                                    label:"Manual",
                                    dataKey : "manual",
                                    color:"#CFCFCF",
                                }
                            ]}
                            getSeries={(item) => item?.source ? "manual" : "via_link" }
                        />
                        </div>
                        <div className="tools-box">
                            <div className="tools-cards">
                                <div className="link-card"
                                    style={{
                                        background: document?.is_link_active ? "#EA723C33" : null,
                                    }}
                                >
                                    <div className="link-card-top">
                                        <CopyLinkIcon 
                                            className="link-card-icon" 
                                            style={{
                                                background : document?.is_link_active ? "#EA723C80" : "#0000004D",
                                            }}
                                            color={!document?.is_link_active ? "black" : "#FFF"}
                                        />
                                        <CustomSwitch 
                                            checked={document?.is_link_active}
                                            onChange={handleChangeLinkActive}
                                            sx={{
                                                '& .MuiSwitch-track': {
                                                    backgroundColor: '#989898',
                                                },                                    
                                            }}
                                        />
                                    </div>
                                    <div className="link-card-middle">Copy & Share</div>
                                    <div className="link-card-bottom">
                                        <div className="likn-card-bottom-text">You can share web career form link with Candidate</div>
                                        <Button
                                            onClick={handleCopyWebCareersLink}
                                            style={{
                                                padding:"0.5rem 0.2rem",
                                                boxSizing:"border-box",
                                                fontSize:"0.75rem",
                                                fontWeight:"600",
                                                margin:"0rem",
                                                width:"fit-content",
                                                minWidth:"fit-content",
                                                height:"1rem",
                                                borderRadius:"0.5rem",
                                                textDecoration:"none",
                                                textTransform:"none",
                                                color: document?.is_link_active ? "#EA723C" : null,
                                            }}
                                            disabled={!document?.is_link_active}
                                        >Copy Link</Button>
                                    </div>
                                </div>
                                <QuickLinkCard 
                                    className="rna-card"
                                    title={"Rank & Analyze"}
                                    content={"You can check the score for resumes with selected JD"}
                                    rgbBackground={[234, 114, 60]}
                                    CardIcon={RankAndAnalyzeIcon}
                                    to={`/rank-and-analyze?filter=job-descriptions&id=${document?._id}`}
                                />
                            </div>
                            <div className="candidates-list">
                                <div className="candidates-list-header">
                                    <div>Candidates</div>
                                    <Link to={`/resumes?target=${document._id}`}>View All</Link>
                                </div>
                                {
                                    listResumes.map((item, index)=>(
                                        index > 4 ? null : 
                                        <div key={index} className="candidates-list-item">
                                            <FilenameRenderer value={item.filename} data={item} />
                                            <PreviewRenderer style={{width:"2.5rem", height:"2.5rem", padding:"0rem"}} data={item} onPreview={handlePreviewDocument} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="jd-page-content-bottom">
                        <QuickLinkCard 
                            className="quick-link-cards"
                            title={"New Resumes"}
                            content={    
                                <div className="card-resume-count"><span>{resumeCounts.new}</span> candidate(s)</div>
                            }
                            rgbBackground={[9, 114, 234]}
                            CardIcon={NewResumesIcon}
                            to={`/resumes?target=${document?._id}&status=new`}
                        />
                        <QuickLinkCard 
                            className="quick-link-cards"
                            title={"Shortlisted"}
                            content={
                                <div className="card-resume-count"><span>{resumeCounts.shortlisted}</span> candidate(s)</div>
                            }
                            rgbBackground={[0, 179, 80]}
                            CardIcon={ShortlistedIcon}
                            to={`/resumes?target=${document?._id}&status=shortlisted`}
                        />
                        <QuickLinkCard 
                            className="quick-link-cards"
                            title={"Interview Scheduled"}
                            content={
                                <div className="card-resume-count"><span>{resumeCounts.interview_scheduled}</span> candidate(s)</div>
                            }
                            rgbBackground={[237, 0, 201]}
                            CardIcon={InterviewScheduledIcon}
                            to={`/resumes?target=${document?._id}&status=interview_scheduled`}
                        />
                    </div>
                </div>
            </ResponsiveWrapper>
        </div>
        </>
    )
}

export default JDPage;