import { DynamicInputGroup, FileDropInput, LoadingIndicator } from "@components/index";
import { applyForJob, getJobInfo } from "@services/webCareerService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorPage } from "..";
import { pageErrors } from "@constants/errorPageInfo";
import "./web-careers-page.css";
import { Button } from "@mui/material";
import { CustomAutoComplete, CustomTextField } from "@components/CustomMUIInputs";
import { toast } from "react-toastify";

const WebCareersPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [jobInfo, setJobInfo] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [formFields, setFormFields] = useState({
        'first_name':'', 'last_name':'', 'email':'', 'mobile_number':''
    });
    const [formErrors, setFormErrors] = useState({});
    
    const [resume, setResume] = useState([]);
    const [hardSkills, setHardSkills] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [educationDetails, setEducationDetails] = useState([]);
    
    const handleFieldChange = (event) => {
        try{
            const {name, value} = event.target;
            setFormFields(prev => ({...prev, [name] : value}));
        }catch(error){
            console.log("Filed couldn't change due to : ", error)
        }
    }
    
    const educationDetailsRenderer = useCallback((item) => (
        <>
            <div className="education-details-degree">{item.degree} {item.branch ? '-' : null} {item.branch}</div>
            <div className="education-details-iname">{item.institute_name}</div>
            <div className="education-details-duration">{item.start_date} to {item.end_date || "Present"}</div>
        </>
    ), [])
    
    const workExperienceRenderer = useCallback((item) => (
        <>
            <div className="education-details-degree">{item.designation}</div>
            <div className="education-details-iname">{item.company_name}</div>
            <div className="education-details-duration">{item.start_date} to {item.end_date || "Present"}</div>
        </>
    ), [])

    const isFormValid = useMemo(()=>{
        return resume.length && Object.entries(formFields).every(([_, value]) => !!value)
    }, [resume, formFields])

    const handleApply = async () => {
        window.onbeforeunload = ()=>{};
        setFormErrors({});
        if(!resume.length){
            return setFormErrors(prev=>({...prev, resumeFileError:"Resume is not Selected"}))
        }
        if(formFields.email){
            const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
            if(!emailRegex.test(formFields.email)){
                return setFormErrors(prev=>({...prev, email:"Enter a Valid Email!"}))
            }
        }

        const formData = new FormData();
        formData.append('resume', resume[0]);
        
        const formJson = {...formFields};
        formJson.candidate_name = `${formFields.first_name} ${formFields.last_name}`;
        delete formJson['first_name'];
        delete formJson['last_name'];
        formJson.hard_skills = hardSkills;
        formJson.soft_skills = softSkills;
        formJson.education = educationDetails;
        formJson.work_experience = workExperiences;

        formData.append("data", JSON.stringify(formJson))

        try{
            await applyForJob(jobInfo._id, formData);
            toast.success("Form Submitted Successfully", {closeOnClick:true});
            localStorage.setItem(jobInfo._id, true);
            navigate(0);
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }
    }

    useEffect(()=>{
        const fetchJobInfo = async (jobID) => {
            try{
                const jobInfo = await getJobInfo(jobID);
                setJobInfo(jobInfo);
            }catch(error){
                setError(error);
            }finally{
                setLoading(false);
            }
        }
        
        const pathSegments = location.pathname.split("/");
        const jobID = pathSegments[pathSegments.length - 1];
        if(localStorage.getItem(jobID)){
            setSubmitted(true);
            setLoading(false);
            return;
        }
        fetchJobInfo(jobID);
    }, [location.pathname])

    return (
        loading ? <LoadingIndicator /> 
        :
        error ? <ErrorPage error={pageErrors.JOB_NOT_FOUND} /> 
        :
        submitted ? 
        <div className="web-careers-page-container">
            <div className="web-careers-form">
                <header>{"Thank You for Your Submission!"}</header>
                <div className="submitted-msg">Your Application Has Been Received</div>
            </div>
        </div>
        : 
        <div className="web-careers-page-container">
            <div className="web-careers-form">
                <header>{jobInfo.job_title || "Web Careers Form"}</header>
                <div className="web-careers-fields">
                    <h3 className="web-careers-header">
                        Resume
                    </h3>
                    
                    <label htmlFor="web-careers-resume">Upload Resume <span className="required">*</span></label>
                    <FileDropInput 
                        id="web-careers-resume" className="web-careers-file-input" multiple={false} 
                        defaultValue={resume}
                        onChange={setResume}
                        error={!!formErrors.resumeFileError}
                        helperText={formErrors.resumeFileError}
                    />
                    
                    <h3 className="web-careers-header">
                        Personal Information
                    </h3>
                    <div className="web-careers-section">
                        <CustomTextField 
                            id={"webCareersFirstName"}
                            name={"first_name"}
                            placeholder="Enter First Name"
                            label={"First Name"}
                            value={formFields.first_name}
                            onChange={handleFieldChange}
                            required
                        />
                        <CustomTextField 
                            id={"webCareersLastName"}
                            name={"last_name"}
                            placeholder="Enter Last Name"
                            label={"Last Name"}
                            value={formFields.last_name}
                            onChange={handleFieldChange}
                            required
                        />
                        <CustomTextField 
                            id={"webCareersEmailID"}
                            name={"email"}
                            placeholder="Enter Email ID"
                            label={"Email ID"}
                            value={formFields.email}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                            onChange={handleFieldChange}
                            required
                        />
                        <CustomTextField 
                            id={"webCareersMobileNumber"}
                            name={"mobile_number"}
                            placeholder="Enter Mobile Number"
                            label={"Email Mobile Number"}
                            value={formFields.mobile_number}
                            onChange={handleFieldChange}
                            required
                        />
                    </div>

                    <h3 className="web-careers-header">
                        Skills
                    </h3>
                    <CustomAutoComplete 
                        multiple freeSolo options={[]} 
                        id={"webCareersHardSkills"}
                        label={"Hard Skills"}
                        placeholder={"Enter Hard Skills"}
                        value={hardSkills}
                        onChange={(e, newVal) => setHardSkills(newVal)}
                    />
                    <CustomAutoComplete 
                        multiple freeSolo options={[]} 
                        id={"webCareersSoftSkills"}
                        label={"Soft Skills"}
                        placeholder={"Enter Soft Skills"}
                        value={softSkills}
                        onChange={(e, newVal) => setSoftSkills(newVal)}
                    />

                    <DynamicInputGroup 
                        id={"education"}
                        label={"Education Details"}
                        fields={[
                            {label:"Institute Name", name:"institute_name", placeholder:"Enter Institute Name", required:true},
                            {label:"Degree", name:"degree", placeholder:"Enter Degree", required:true},
                            {label:"Branch (if applicable)", name:"branch", placeholder:"Enter Branch"},
                            {inputType:"date", label:"Start Date", name:"start_date", placeholder:"Select Start Date", required:true},
                            {inputType:"date", label:"End Date (if applicable)", name:"end_date", placeholder:"Select End Date"}
                        ]}
                        value={educationDetails}
                        onChange={setEducationDetails}
                        itemRenderer = {educationDetailsRenderer}
                    />

                    <DynamicInputGroup 
                        id={"work"}
                        label={"Work Experience"}
                        fields={[
                            {label:"Company Name", name:"company_name", placeholder:"Enter Company Name", required:true},
                            {label:"Designation", name:"designation", placeholder:"Enter Designation", required:true},
                            {inputType:"date", label:"Start Date", name:"start_date", placeholder:"Select Start Date", required:true},
                            {inputType:"date", label:"End Date (if applicable)", name:"end_date", placeholder:"Select End Date"}
                        ]}
                        value={workExperiences}
                        onChange={setWorkExperiences}
                        itemRenderer = {workExperienceRenderer}
                    />

                    <center>
                        <Button
                            onClick={handleApply}
                            variant="contained"
                            style={{
                                width:"50%",
                                background:"#EA723Cd3",
                                textTransform:"none",
                                borderRadius:"6px",
                                fontWeight:"600",
                                margin:"1rem"
                            }} 
                            disabled={!isFormValid}
                        >Apply</Button>
                    </center>
                </div>
            </div>
        </div> 
    );
}

export default WebCareersPage;