import useResponsiveView from "@hooks/useResponsiveView";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "@styles/agtable.css";
import { CustomGridPagination } from "..";
import { useCallback, useMemo, useState } from "react";

const CustomGrid = ({children, gridId, rowData, columnDefs, containerStyle={mobile:{}, desktop:{}}, ...params}) => {
    const isMobile = useResponsiveView();
    const [gridAPI, setGridAPI] = useState(null);
    // const [loading, setLoading] = useState(true);

    // useEffect(()=>{
    //     setLoading(false);
    // }, [])
    
    const getRowId = useCallback((params) => params.data._id, []);
    
    const handleGridReady = useCallback((params)=>{
        setGridAPI(params.api);
    }, []);

    const defaultColDef = useMemo(()=>({wrapHeaderText:true, minWidth:(isMobile ? 150 : 100), flex:3, resizable: false, sortable:false, cellStyle:{textAlign:"center"}, headerClass:"centered-header"}), [isMobile]);
    
    return (
        // loading ? <LoadingIndicator type="text" /> : 
        <>
            <div key={gridId} id={gridId} className={"ag-theme-quartz" + (isMobile ? " mobile" : "")} style={isMobile ? { width: '100%', borderRadius:"16px", flexGrow:"1", margin:"1rem", overflow:"auto", ...containerStyle.mobile } : { width: 'calc(2rem + 100%)', flex:1, marginLeft:"-1rem", ...containerStyle.desktop }}>
                {children}
                <AgGridReact 
                    getRowId={getRowId}
                    pagination={true} 
                    defaultColDef={defaultColDef}
                    rowData={rowData} 
                    columnDefs={columnDefs}
                    rowHeight={"70px"}
                    suppressCellFocus
                    rowBuffer={0}
                    onGridReady={handleGridReady}
                    suppressPaginationPanel
                    suppressHorizontalScroll
                    suppressRowHoverHighlight
                    paginationPageSize={100}
                    gridId={gridId}
                    {...params}
                />
            </div>
            <CustomGridPagination gridAPI={gridAPI} />
        </>
    )
}

export default CustomGrid;