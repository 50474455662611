import useResponsiveView from "@hooks/useResponsiveView";
import { Paper } from "@mui/material";

const ResponsiveWrapper = ({children}) => {
    const isMobile = useResponsiveView();

    return (
        isMobile ?
        <>{children}</>
        :
        <Paper
            elevation={1}
            sx={{
                width:"93%",
                marginTop:"0.5rem",
                padding:"0rem 1rem",
                height:"100%",
                borderRadius:"32px",
                display:"flex",
                flexDirection: "column"
            }}
        >
            {children}
        </Paper>
    )
}

export default ResponsiveWrapper;