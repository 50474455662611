import { CustomSwitch } from "@components/CustomMUIInputs";
import { isSuperAdmin } from "@utils/accessControl";

const AccessRenderer = ({data:user, onUpdate=(user_id, update)=>{}})=>{
    return (
        <CustomSwitch
            id={user._id}
            disabled={isSuperAdmin(user)}
            checked={user.verified === null ? false : user.verified} 
            onClick={(event)=>{
                onUpdate(user._id, {verified:!user.verified});
            }}
        />
    )
}

export default AccessRenderer;