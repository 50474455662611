import { useAuth } from "@contexts/AuthContext";
import "./home.css";
import useResponsiveView from "@hooks/useResponsiveView";
import { Button, Paper } from "@mui/material";
import { useEffect, useMemo } from "react";
import { isSuperAdmin } from "@utils/accessControl";
import dayjs from "dayjs";
import { JobDescriptionsIcon, ResumesIcon, UsersIcon } from "@icons/DashboardIcons";
import { useDocuments } from "@contexts/DocumentsContext";
import { fetchUsers } from "@services/usersService";
import { useQuery } from "@tanstack/react-query";

const Home = ({onTour}) => {
    const isMobile = useResponsiveView();
    const { documents } = useDocuments()

    const queryKeys = useMemo(()=>['users'], []);
    const { data:users=[], isLoading } = useQuery({
        queryKey : queryKeys,
        queryFn : fetchUsers,
        staleTime : Infinity
    })

    const numDocuments = useMemo(()=>documents?.reduce((acc, {document_type})=>{
        acc[document_type] = (acc[document_type] || 0) + 1;
        return acc;
    }, {}), [documents])

    const { user } = useAuth();

    return (
        <>
            {
                isSuperAdmin(user) ? 
                <div className="home-page-container">
                    <div className="home-page-top">
                        <div className="home-page-top-containers greetings">
                            <div className="home-page-greetings-title">Welcome to Scan2Hire!</div>
                            <p>Simplify your hiring process with advanced technology. Scan, Filter, and Hire the best talent in a few clicks.</p>
                        </div>

                        <div className="home-page-top-containers plan-info">
                            <div className="plan-info-title">Plan Details</div>
                            <div className="plan-info-item">
                                <div className="plan-info-subtitle" aria-hidden />
                                <div>{user?.plan?.name} Plan</div>
                            </div>
                            <div className="plan-info-item">
                                <div className="plan-info-subtitle">Start Date</div>
                                <div>{dayjs(user?.plan?.start_date).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="plan-info-item">
                                <div className="plan-info-subtitle">End Date</div>
                                <div>{dayjs(user?.plan?.start_date).add(user?.plan?.duration_in_months, 'month').format("DD/MM/YYYY")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="home-page-bottom">
                        <Paper className="home-page-cards"
                            style={{
                                borderRadius:isMobile ? "18px" : "28px"
                            }}
                        >
                            <ResumesIcon className="home-page-cards-icon" />
                            <div>
                                <div className="home-page-card-title">
                                    Active Resumes
                                </div>
                                <div>
                                    <b>{numDocuments?.resumes || 0}</b> / {user?.plan?.max_active_resumes}
                                </div>
                            </div>
                        </Paper>
                        <Paper className="home-page-cards"
                            style={{
                                borderRadius:isMobile ? "18px" : "28px",
                            }}
                        >
                            <JobDescriptionsIcon className="home-page-cards-icon" />
                            <div>
                                <div className="home-page-card-title">
                                    Open Jobs
                                </div>
                                <div>
                                    <b>{numDocuments['job-descriptions'] || 0}</b> / {user?.plan?.max_open_jobs}
                                </div>
                            </div>
                        </Paper>
                        <Paper className="home-page-cards"
                            style={{
                                borderRadius: isMobile ? "18px" : "28px"
                            }}
                        >
                            <UsersIcon className="home-page-cards-icon" />
                            <div>
                                <div className="home-page-card-title">
                                    Users
                                </div>
                                <div>
                                    <b>{users?.length || 0}</b> / {user?.plan?.max_users}
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                :
                isMobile ?
                // Mobile View
                <div
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width:"100%"
                    }}
                >
                    <div
                        style={{
                            width:"100%",
                            height:"45svh",
                            borderRadius:"16px",
                            backgroundImage:"url(/video.jpg)",
                            backgroundSize:"auto 45svh",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat"
                        }}
                    />
                    <div
                        style={{
                            marginLeft:"1rem",
                            fontSize:"2.5rem",
                            fontWeight:"800",
                            color:"#EA723C",
                            marginTop:"1rem",
                            textAlign:"center"
                        }}
                    >Welcome to Scan2Hire!</div>
                    <p
                        style={{
                            fontSize:"1rem",
                            fontWeight:"500",
                            margin:"0.5rem 1rem 1.5rem 1rem",
                            fontStyle:"italic",
                            textAlign:"center"
                        }}
                    >"Streamline Your Hiring Process: <br /> Introducing Scan2Hire"</p>

                    <Button 
                        variant="contained" 
                        style={{
                            textTransform:'none', 
                            color:"white",
                            borderRadius:"12px",
                            fontWeight:"600",
                            fontFamily:"montserrat", 
                            fontSize:"0.9rem",
                            background:"#EA723C",
                        }}
                        onClick={()=>onTour()}
                    >Take a Tour</Button>
                </div>
                
                :
                
                // Desktop View
                <div className="home-container">
                    <Paper
                        elevation={0}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            borderRadius:"32px",
                            background:"#EA723C1A",
                        }}
                    >
                        <div
                            style={{
                                marginLeft:"1rem",
                                fontSize:"1.5rem",
                                fontWeight:"800",
                                color:"#EA723C",
                                marginTop:"1rem",
                            }}
                        >Welcome to Scan2Hire!</div>
                        <p
                            style={{
                                fontSize:"0.9rem",
                                fontWeight:"500",
                                margin:"0.5rem 1rem 1.5rem 1rem",
                                fontStyle:"italic"
                            }}
                        >"Streamline Your Hiring Process: Introducing Scan2Hire"</p>
                    </Paper>

                    <Paper
                        elevation={3}
                        sx={{
                            width:"93%",
                            marginTop:"1.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            backgroundImage:"url(/video.jpg)",
                            backgroundSize:"120% auto",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat"
                        }}
                    />
                </div>
            } 
        </>
    )
}

export default Home;