import { ViewJobIcon } from "@icons/ActionsIcons";
import PreviewIcon from "@icons/ActionsIcons/PreviewIcon";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const PreivewRenderer = ({data:document, style={}, onPreview=(document)=>{}})=>{
    return (
        <div
            style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            }}
        >            
            {
                document.document_type === "resumes" || document.status === "archived" ?
                <Tooltip title="Preview" arrow>
                    <IconButton style={style} className="action-btn" onClick={()=>onPreview(document)}>
                        <PreviewIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
                :
                <Tooltip title="View Job" arrow>
                    <Link className="action-btn" to={`/job-descriptions/${document._id}`} style={{height:"32px", ...style}}>
                        <ViewJobIcon className="action-svg-icon" />
                    </Link>
                </Tooltip>
            }
        </div>
    )
}

export default PreivewRenderer;