const NewResumesIcon = ({color="#1E1E1E", ...params}) => {
    return (
        <svg width="29" height="34" viewBox="0 0 29 34" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.2002 20.2C28.2002 26.234 28.2002 29.251 26.2085 31.1255C24.2168 33 21.0113 33 14.6002 33H13.3638C8.1459 33 5.53694 33 3.72511 31.7235C3.20599 31.3577 2.74512 30.924 2.35651 30.4354C1.00019 28.7301 1.00019 26.2746 1.00019 21.3636V17.2909C1.00019 12.5498 1.00019 10.1793 1.75049 8.286C2.9567 5.24228 5.50761 2.84141 8.74157 1.70616C10.7532 1 13.2719 1 18.3093 1C21.1878 1 22.6271 0.999999 23.7766 1.40352C25.6245 2.05224 27.0822 3.42416 27.7715 5.16343C28.2002 6.24531 28.2002 7.59991 28.2002 10.3091V20.2Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M1 17C1 14.0545 3.38781 11.6667 6.33333 11.6667C7.39859 11.6667 8.65446 11.8533 9.69018 11.5758C10.6104 11.3292 11.3292 10.6104 11.5758 9.69018C11.8533 8.65446 11.6667 7.39859 11.6667 6.33333C11.6667 3.38782 14.0545 1 17 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 25L9.8 25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 18.6L16.2 18.6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default NewResumesIcon;