import { useCallback } from "react";
import styles from "./LoadingIndicator.module.css";
import { CircularProgress } from "@mui/material";

const LoadingIndicator = ({type = "circular"})=>{
    const getLoader = useCallback((type)=>{
        switch(type){
            case "circular":
                return <CircularProgress />
            case "text":
                return "Loading..."
            default:
                return "..."
        }
    }, [])

    return (
    <div className={styles.container}>
        {
            getLoader(type)
        }
    </div>
)}

export default LoadingIndicator;