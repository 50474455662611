import { Autocomplete, FormControl, IconButton, InputAdornment, MenuItem, Select, styled, Switch, TextField } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { useEffect, useMemo, useState } from "react";

const CustomTextField = ({id, label, sx, style, InputProps, required=false, EndIcon=null, onAction=()=>{}, inputType='text', defaultValue="", onChange=(event)=>{}, ...props}) => {
    const [hasText, setHasText] = useState(defaultValue !== "");
    const [inputValue, setInputValue] = useState(defaultValue);
    const handleChange = (event, ...params)=>{
        setHasText(event.target.value !== "");
        setInputValue(event.target.value);
        onChange(event, ...params);
    }

    useEffect(() => {
        setInputValue(defaultValue);
        setHasText(defaultValue !== "");
    }, [defaultValue]);
    
    return (
        <div
            style={{
                width:"100%"
            }}
        >
            {
              label && <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label} {required ? <span style={{color:"red"}}>*</span> : null}</label>
            }
            <TextField
                {...props}
                required={required}
                type={inputType || "text"}
                value={inputValue}
                onChange={handleChange}
                style={{ width: "100%", margin:"0.5rem 0", ...style }}
                InputProps={{
                    style: { borderRadius: "10px", fontFamily: "Montserrat", ...InputProps },
                    endAdornment:(
                        EndIcon && 
                        <InputAdornment position="end" >
                            <IconButton style={{padding:"0px"}} onClick={onAction}>
                                <EndIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    style:{
                        display:"none"
                    }
                }}
                margin="none"
                id={id}
                sx={{
                    "& .MuiOutlinedInput-root": {
                    transition:"background-color ease-in-out 300ms",
                    width:sx?.width,
                    height:sx?.height,
                    fontSize:sx?.fontSize,
                    "& fieldset": {
                        borderColor: hasText ? "black" : "gray",
                        transition:"border-color ease-in-out 300ms, border-style ease-in-out 300ms",
                    },
                    "&:hover fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                        borderStyle : sx?.borderStyle || "solid"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                        borderStyle : sx?.borderStyle || "solid",
                        borderWidth : "1px",
                    },
                    "&:hover": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                    "&.Mui-focused": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)"
                    },
                    },
                    "& .MuiFormLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                        color: "rgb(234, 114, 60)",
                    },
                    ...sx
                    },
                }}
            />
        </div>
    )
}

const Placeholder = styled("div")({
    color:"rgba(0, 0, 0, 0.4)"
})

const CustomSelect = ({id, label, placeholder, sx, options, required=false, menuItemStyle, defaultValue="", onChange=(event, value, ...params)=>{}, ...props}) => {
    const [hasText, setHasText] = useState(defaultValue !== "");
    const handleChange = (event)=>{
        const value = event.target?.value;
        setHasText(value !== "");
        onChange(event, value);
    }

    return (
        <div
            style={{
                width:"100%",
                display:"flex",
                flexDirection:"column"
            }}
        >
            <FormControl
                margin="none"
                sx={{
                width: "100%",
                minHeight:"0",
                "& .MuiOutlinedInput-root": {
                    transition:"background-color ease-in-out 200ms",
                    borderRadius: "10px",
                    height:sx?.height,
                    "& fieldset": {
                    transition:"border-color ease-in-out 200ms",
                    borderColor: hasText ? "black" : "gray",
                    },
                    "&:hover fieldset": {
                    borderColor: "rgb(234, 114, 60)",
                    borderStyle : sx?.borderStyle || "solid",
                    },
                    "&.Mui-focused fieldset": {
                    borderColor: "rgb(234, 114, 60)",
                    borderStyle : sx?.borderStyle || "solid",
                    borderWidth : "1px",
                    },
                    "&:hover": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                    "&.Mui-focused": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                },
                "& .MuiSelect-select": {
                    minHeight:"0px",
                    "&:hover": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                },
                "& .MuiInputLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                    color: "rgb(234, 114, 60)",
                    },
                },
                ...sx
                }}
            >
                {label!=="" && <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} id={`label-${id}`} htmlFor={id}>{label} {required ? <span style={{color:"red"}}>*</span> : null}</label>}
                <Select
                    id={id}
                    labelId={`label-${id}`}
                    defaultValue={defaultValue}
                    {...props}
                    required={required}
                    displayEmpty
                    onChange={handleChange}
                    renderValue={value=> value !== "" ? value : <Placeholder>{placeholder}</Placeholder>} 
                >
                {options && options.length > 0 ? (
                    options.map((name, index) => (
                    <MenuItem
                        value={name}
                        key={index}
                        style={{ textTransform: "capitalize", ...menuItemStyle }}
                    >
                        {name}
                    </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" disabled>
                        No roles available
                    </MenuItem>
                )}
                </Select>
            </FormControl>
        </div>
    )
}

const CustomAutoComplete = ({id, name, sx:defaultSX={}, style, label, placeholder, required, ...params})=>{
    const {borderStyle, ...sx} = useMemo(()=>defaultSX, [defaultSX]);
    
    return (
        <div
            style={{
                width:"100%"
            }}
        >
            {label && <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label} {required ? <span style={{color:"red"}}>*</span> : null}</label>}
            <Autocomplete 
                renderInput={(params) => <TextField 
                    id={id}
                    name={name}
                    placeholder={placeholder} {...params}
                    required={required}
                />}
                sx={{
                    "& .MuiAutocomplete-root": {
                        "&:hover": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                        },
                        "&.Mui-focused": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                        },
                    },
                    "& .MuiOutlinedInput-root": {
                        color:"var(--text-color)",
                        fontFamily:"Montserrat",
                        borderRadius: "10px",
                        "& fieldset": {
                        borderColor: "gray",
                        },
                        "&:hover fieldset": {
                        borderStyle : borderStyle || "solid",            
                        borderColor: "rgb(234, 114, 60)",
                        },
                        "&.Mui-focused fieldset": {
                        borderWidth:"1px",
                        borderStyle : borderStyle || "solid",            
                        borderColor: "rgb(234, 114, 60)",
                        },
                    },
                    "& .MuiAutocomplete-inputRoot": {
                        "&:hover": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        color: "gray",
                        "&.Mui-focused": {
                        color: "rgb(234, 114, 60)",
                        },
                    },
                    '.dark & .MuiSvgIcon-root' : {
                        color:"gray"
                    },
                    ...sx
                }}
                style={{...style, margin:"0.5rem 0rem"}}
                {...params}
            />
        </div>
    )
}

const CustomFileInput = ({id, label, sx, style, InputProps, defaultValue=[], onChange=(event)=>{}, ...props}) => {
    const [hasText, setHasText] = useState(defaultValue?.length !== 0);
    const [inputValue, setInputValue] = useState(defaultValue);
    const handleChange = (value, ...params)=>{
        setHasText(value?.length !== 0);
        setInputValue(value);
        onChange(value, ...params);
    }
    
    return (
        <div
            style={{
                width:"100%"
            }}
        >
            { label && <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label}</label>}
            <MuiFileInput
                {...props}
                value={inputValue}
                onChange={handleChange}
                style={{ width: "100%", margin:"0.5rem 0", ...style }}
                InputProps={{
                    style: { borderRadius: "10px", fontFamily: "Montserrat", ...InputProps },
                }}
                InputLabelProps={{
                    style:{
                        display:"none",
                    }
                }}
                margin="none"
                id={id}
                sx={{
                    "& .MuiOutlinedInput-root": {
                    transition:"background-color ease-in-out 300ms",
                    "& fieldset": {
                        borderColor: hasText ? "black" : "gray",
                        transition:"border-color ease-in-out 300ms"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                        borderStyle : sx?.borderStyle || "solid",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                        borderStyle : sx?.borderStyle || "solid",
                        borderWidth : "1px",
                    },
                    "&:hover": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                    "&.Mui-focused": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)"
                    },
                    },
                    "& .MuiFormLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                        color: "rgb(234, 114, 60)",
                    },
                    ...sx
                    },
                }}
            />
        </div>
    )
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 36, 
    height: 19, 
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '200ms',
      '&.Mui-checked': {
        transform: 'translateX(17px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#EA723C',
          opacity: 1,
          border: 0,
        },
      },
      '&.Mui-disabled': {
        '& .MuiSwitch-thumb': {
          border: '1px solid #CDCDCD',
        },
        '& + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 15, 
      height: 15,
      backgroundColor: 'white',
      border: '1px solid #CDCDCD',
    },
    '& .MuiSwitch-track': {
      borderRadius: 12, 
      backgroundColor: '#CDCDCD',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 200,
      }),
    },
}));

export { CustomTextField, CustomSwitch, CustomSelect, CustomAutoComplete, CustomFileInput };