import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"

const useResourceMutation = (
    queryKeys, operation, mutationFn,         
    onSuccess,
    onError = (error) => console.log(error)
) => {
    const queryClient = useQueryClient();

    const successCallbacks = useMemo(()=>{
        return {
            'add' : (newItem, {isBatch = false}={})=>{
                queryClient.setQueryData(queryKeys, (prevItems)=>{
                    return isBatch ?
                    [...prevItems, ...newItem]
                    :
                    [...prevItems, newItem];
                })
            },

            'update' : (updatedItem, {isBatch = false}={})=>{
                queryClient.setQueryData(queryKeys, (prevItems)=>{
                    return isBatch ?
                        prevItems.map(item => {
                            const update = updatedItem.find(ui => ui._id === item._id);
                            return update || item;
                        })
                        :
                        prevItems.map(item=>(
                            item._id === updatedItem._id ? updatedItem : item
                        ));
                })
            },

            'delete' : (itemID, {isBatch = false}={})=>{
                queryClient.setQueryData(queryKeys, (prevItems)=>{
                    return isBatch ?
                    prevItems.filter(item => !itemID.includes(item._id))
                    :
                    prevItems.filter(item => item._id !== itemID);
                })
            }
        }
    }, [queryKeys, queryClient])

    return useMutation({
        mutationFn : async (variables)=>{
            const { data, isBatch, cacheOnly=false } = variables;
            if(!variables.data) throw new Error("Mutation arguments needs : data");
            if(cacheOnly) return data;
            return isBatch ? 
                mutationFn(data, isBatch) : mutationFn(data);
        },
        onSuccess : (data, variables, context)=>{
            const { ignoreSuccessCallback=false } = variables;
            if(ignoreSuccessCallback) return;
            successCallbacks[operation](data, variables, context);
        },
        onError : onError
    })
}

export default useResourceMutation;