import "./document-list.css";
import { lazy, memo, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Fade, Chip, Paper, IconButton, Tabs, Tab, Collapse } from "@mui/material";
import { AutorenewOutlined, SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { ArchiveIcon, DeleteIcon, PlusIcon, SearchIcon } from "@icons/ActionsIcons";
import { FillButton } from "@components";
import { toast } from "react-toastify";
import { DocumentTypeIcon } from "@icons/FilesIcons";
import Lottie from "react-lottie";
import deleteAnimation from "@animations/deleteAnimation.json";
import useResponsiveView from "@hooks/useResponsiveView";
import { useDocuments } from "@contexts/DocumentsContext";
import { useAuth } from "@contexts/AuthContext";
import { ActionsRenderer, FilenameRenderer, PreviewRenderer, StatusRenderer } from "@renderers/documents-renderers";
import { documentsDialogTypes as dDialogTypes } from "@constants/dialogsEnums";
import ConfirmDialog from "@components/ConfirmDialog";
import { DialogForm, DocumentPreview, LoadingIndicator } from "@components/index";
import { RENAME_DOCUMENT } from "@constants/forms";
import { getFormJson } from "@utils/formUtils";
import UploadJDDialog from "@components/UploadDialogs/UploadJDDialog";
import { CustomTextField } from "@components/CustomMUIInputs";
import { FilterIcon } from "@icons/DialogsIcons";
import { documentStatusRenderMap, getDocumentStatusList } from "@constants/documentStatusRenderMap";
import UploadResumeDialog from "@components/UploadDialogs/UploadResumeDialog";
import { useLocation } from "react-router-dom";
import ResumesFilterDrawer from "@components/FilterDrawer/ResumesFilterDrawer";
import useSearchParams from "@hooks/useSearchParams";
import ResponsiveWrapper from "@components/ResponsiveWrapper";

const CustomGrid = lazy(()=>import("@components/CustomGrid/CustomGrid"));

const DocumentList = ({documentType}) => {
    const isMobile = useResponsiveView();
    const location = useLocation();
    const updateSearchParams = useSearchParams();

    const { documents, isLoading, uploadFile, isStale, updateDocumentStatus, syncDocuments, mutateDeleteDocument, mutateUpdateDocument } = useDocuments(documentType);
    const { user } = useAuth();
    const DOCUMENT_LIMIT_REACHED = documents?.length >= (documentType === 'resumes' ? user?.plan.max_active_resumes : user?.plan.max_open_jobs);
    
    const [deletingMultiple, setDeletingMultiple] = useState(false);
    const [openDialog, setOpenDialog] = useState(dDialogTypes.NONE);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [RenameErrors, setRenameErrors] = useState({});
    const documentStatusList = useMemo(()=>getDocumentStatusList(documentType), [documentType]);
    
    const [statusFilter, setStatusFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const statusParam = queryParams.get("status")
        if(statusParam && documentStatusRenderMap[statusParam]){
            return statusParam
        }
        return ""
    });

    const [targetFilter, setTargetFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const targetParam = queryParams.get("target");
        if(targetParam) return targetParam;
        return "";
    })
    const [sourceFilter, setSourceFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const sourceParam = queryParams.get("source");
        if(sourceParam) return sourceParam;
        return "";
    })
 
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const [searchFilterText, setSearchFilterText] = useState("");
    
    const isExternalFilterPresent = useCallback(()=>{
        return searchFilterText !== "" || statusFilter !== "" || targetFilter !== "" || sourceFilter !== "";
    }, [searchFilterText, targetFilter, sourceFilter, statusFilter]);
    
    const doesExternalFilterPass = useCallback((node)=>{
        return (
            (searchFilterText === "" || node.data?.filename?.toLowerCase().includes(searchFilterText?.toLowerCase()))
            &&
            (statusFilter === "" || node.data?.status === statusFilter)
            &&
            (targetFilter === "" || node.data?.target === targetFilter)
            &&
            (sourceFilter === "" || ((sourceFilter === "manual") ? (!!node.data?.source) : (!node.data?.source)))
        );
    }, [searchFilterText, targetFilter, sourceFilter, statusFilter]);

    const handleStatusUpdate = async (documentId, actionItem) => {
        try {
            await updateDocumentStatus(documentId, actionItem)
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            // nothing to do currently
        }
    }

    const handleAction = (document, type)=>{
        setSelectedDocument(document);
        switch(type){
            case 'rename':
                setOpenDialog(dDialogTypes.RENAME_DOCUMENT);
                break;
            case 'archive':
                setOpenDialog(dDialogTypes.ARCHIVE_DOCUMENT);
                break
            case 'delete':
                setOpenDialog(dDialogTypes.DELETE_DOCUMENT);
                break
            default:
                break;
        }
    }

    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:5,
            minWidth : isMobile ? 350 : undefined,
            headerClass:"not-centered-header-cell",
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",

            },
            cellRenderer: memo(FilenameRenderer),
            cellClass:"name-cell",
            checkboxSelection:true, headerCheckboxSelection:true, headerCheckboxSelectionFilteredOnly:true,
        },
        {
            headerName:"Status", flex:4, cellClass:"centered-cell", field:"status", cellRenderer: StatusRenderer,
            cellRendererParams:{
                onUpdate:handleStatusUpdate
            }
        },
        { headerName: documentType === "resumes" ? 'Preview' : "View Job", flex:2, cellClass:"centered-cell", cellRenderer: memo(PreviewRenderer), 
            cellRendererParams:{
                onPreview:(document)=>{
                    setSelectedDocument(document);
                    setOpenDialog(dDialogTypes.PREVIEW_DOCUMENT);
        }}},
        { headerName: 'Actions', flex:2, cellClass:"centered-cell", cellRenderer: memo(ActionsRenderer), 
            cellRendererParams:{
                onAction:handleAction,
                disabled:!user?.access_delete,
                this : "hello world" // probably added for debugging
        }},
        {
            headerName: 'Created At',
            field: 'created_at',
            sortable: true,
            hide: true,  // don't need to show the column - needs for sorting only
            sort: 'desc',
        },
    ])

    const handleRowSelected = useCallback((event)=>{
        setSelectedDocuments(event.api.getSelectedRows());
    }, []);

    const handleUploadDocuments = ({files, additionalDetails})=>{
        if(!files) return;
        for(let i=0; i<files.length; i++){
            uploadFile(files[i], documentType, true, additionalDetails);
        }
    }

    const handleRename = async (event)=>{
        event.preventDefault();
        const formJson = getFormJson(event);
        if(!formJson.filename || !formJson.filename.trim()){
            setRenameErrors({'filename' : "Empty name not allowed"});
            return;
        }
        if(formJson.filename.includes("/")){
            setRenameErrors({"filename" : "Invalid name, '/' not allowed" });
            return;
        }

        try{
            await mutateUpdateDocument({data:[selectedDocument?._id, {filename : formJson.filename}]});
            toast.success("File renamed successfully", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            setOpenDialog(dDialogTypes.NONE);
            setRenameErrors({});
        }
    }

    const handleDeleteDocuments = async (event)=>{
        try{
            if(openDialog === dDialogTypes.DELETE_DOCUMENT){
                await mutateDeleteDocument({data:selectedDocument?._id});
                toast.success("File deleted successfully", {closeOnClick:true})
                setOpenDialog(dDialogTypes.NONE);
            }else{
                // multi
                setOpenDialog(dDialogTypes.NONE);
                setDeletingMultiple(true);
                await mutateDeleteDocument({data:selectedDocuments?.map(item=>item._id), isBatch:true});
                setDeletingMultiple(false);
                toast.success(selectedDocuments.length + " file(s) deleted successfully", {closeOnClick:true});
            }
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(dDialogTypes.NONE);
        }
    }

    useEffect(()=>{
        setColumnsDefs(prev=>prev.map(value=>{
            return value.headerName === "Actions" ?
            {
                ...value,
                cellRendererParams:{...value.cellRendererParams, disabled:!user?.access_delete}
            }
            : value
        }))
    }, [user]);

    return (
        <>
            {
                documentType === "resumes" ?
                <ResumesFilterDrawer 
                    defaultSource={sourceFilter} defaultTarget={targetFilter}
                    open={openDrawer} onClose={()=>setOpenDrawer(false)} 
                    onApplyFilters={(filters)=>{
                        setSourceFilter(filters.source);
                        setTargetFilter(filters.target);
                        updateSearchParams({"source": filters.source, "target": filters.target});
                        setOpenDrawer(false);
                    }}
                />
                : null
            }
            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_DOCUMENT} title="Remove Document"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete the document ${selectedDocument?.filename}?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />
            
            <ConfirmDialog
                open={openDialog === dDialogTypes.ARCHIVE_DOCUMENT} title="Archive Document"
                icon={
                    <ArchiveIcon width="5rem" height="5rem" />
                }
                content={`Are you sure you want to archive the document ${selectedDocument?.filename}?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={()=>{
                    handleStatusUpdate(selectedDocument?._id, "archived");
                    setOpenDialog(dDialogTypes.NONE);
                }}
            />

            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_MULTI} title="Remove Document(s)"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete selected ${selectedDocuments?.length} documents?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />

            <DialogForm 
                {...RENAME_DOCUMENT}
                inputFields={RENAME_DOCUMENT.inputFields.map(item=>{
                    switch(item.name){
                        case 'filename':
                            return {...item, defaultValue:selectedDocument?.filename};
                        case 'cleaned_text':
                            return {...item, defaultValue:selectedDocument?.cleaned_text};
                        default:
                            return item
                    }
                })}
                open={openDialog === dDialogTypes.RENAME_DOCUMENT}
                errors={RenameErrors}
                onSubmit={handleRename}
                onClose={()=>{
                    setOpenDialog(dDialogTypes.NONE)
                    setRenameErrors({});
                }}
            />

            <DocumentPreview 
                open={openDialog === dDialogTypes.PREVIEW_DOCUMENT}
                document={selectedDocument}
                onClose={()=>setOpenDialog(dDialogTypes.NONE)}
            />

            {
                documentType === 'job-descriptions' ?
                <UploadJDDialog
                    open={openDialog === dDialogTypes.UPLOAD_DOCUMENT}
                    onClose={()=>setOpenDialog(dDialogTypes.NONE)}
                    onSubmit={handleUploadDocuments}
                    /> : 
                    <UploadResumeDialog 
                    open={openDialog === dDialogTypes.UPLOAD_DOCUMENT}
                    onClose={()=>setOpenDialog(dDialogTypes.NONE)}
                    onSubmit={handleUploadDocuments}
                />
            }

            <div className="document-list-container">
                <ResponsiveWrapper>
                    <div className="document-list-header-buttons">
                        <Tabs value={statusFilter} scrollButtons={true} 
                            TabIndicatorProps={{
                                style:{ transition:"none"}
                            }} onChange={(e, newVal)=>{
                                setStatusFilter(newVal);
                                updateSearchParams({"status":newVal});
                            }}
                            variant="scrollable" 
                            style={{
                                maxWidth: isMobile ? "100%" : "65%",
                                height:"1rem",
                                display:"flex",
                                alignItems:"center"
                            }}
                            sx={{
                                "& .MuiTab-root" : {
                                    textTransform : "none",
                                },
                                "& .MuiTabs-scrollButtons.Mui-disabled" : {
                                    opacity: 0.3
                                }
                            }}
                            className="document-list-tabs"   
                        >
                            <Tab className="document-list-tab" label={"All"} value={""} 
                                style={{
                                    color: statusFilter === "" ? "black" : "gray"}}
                                icon={
                                    <div className="document-list-tab-number"
                                        style={{
                                            color : statusFilter === "" ? "white" : "gray",
                                            background : statusFilter === "" ? "#EA723C" : "white",
                                        }}
                                    >{documents.length}</div>
                                }
                                iconPosition="end"
                            />
                            {
                                documentStatusList.map(([k, v])=>(
                                    <Tab 
                                        key={k}
                                        className="document-list-tab" label={v.text} value={k} 
                                        style={{color: statusFilter === k ? "black" : "gray"}}
                                        icon={
                                            <div className="document-list-tab-number"
                                                style={{
                                                    color: statusFilter === k ? v.background : v.color,
                                                    background:statusFilter === k ? v.color : v.background
                                                }}
                                            >{documents.filter(item=>item.status === k).length}</div>
                                        }
                                        iconPosition="end"
                                    />
                                ))
                            }
                        </Tabs>
                        <div
                            style={{
                                display:"flex",
                                alignItems:"center"
                            }}
                        >
                            {
                                selectedDocuments.length ?
                                <LoadingButton
                                    variant="contained" 
                                    style={{
                                        background:user?.access_delete ?"#FF4848" : "lightgray",
                                        border:"1px solid " + (user?.access_delete ? "#FF4848" : "lightgray"),
                                        textTransform:"none",
                                        borderRadius:"0.5rem",
                                        fontFamily:"Montserrat",
                                        padding:"0.3rem 0rem",
                                        margin:"0rem 1rem",
                                        height: isMobile ? "2rem" : "2.5rem",
                                        alignSelf:"flex-start",
                                    }}
                                    type="input"
                                    inputMode="file"
                                    onClick={()=>{
                                        setOpenDialog(dDialogTypes.DELETE_MULTI);
                                    }}
                                    loadingIndicator={
                                        <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                                    }
                                    loading={deletingMultiple}
                                    disabled={!user?.access_delete}
                                >
                                    <DeleteIcon color="white" />
                                </LoadingButton>
                                : null
                            }
                            
                            <FillButton 
                                variant="outlined" 
                                className={`add-document-btn ${user?.access_post ? null : "disabled"}`}
                                startIcon={<PlusIcon className="adddoc-svg-icon" />}
                                onClick={()=>{
                                    if(documentType === 'job-descriptions'){
                                        setOpenDialog(dDialogTypes.UPLOAD_DOCUMENT);
                                        return;
                                    }
                                    else if(documentType === "resumes"){
                                        setOpenDialog(dDialogTypes.UPLOAD_DOCUMENT);
                                        return;
                                    }else{
                                        // this should never happen
                                        console.log("CRITICAL : Trying to upload file for unknown document type");
                                    }
                                }}
                                id={documentType + 'UploadButton'}
                                disabled={DOCUMENT_LIMIT_REACHED || !user?.access_post}
                                value={DOCUMENT_LIMIT_REACHED ? `Max ${documentType === "resumes" ? "Resumes" : "JDs"} reached` : "Upload Document"}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="document-list-search-bar">
                        <CustomTextField 
                            sx={{
                                height:"2.8rem"
                            }}
                            placeholder={"Search..."}
                            EndIcon={SearchIcon}
                            defaultValue={searchFilterText}
                            onChange={(event)=>setSearchFilterText(event.target.value)}
                        />
                        <IconButton
                            style={{
                                marginLeft:"0.5rem"
                            }}
                            onClick={()=>setOpenDrawer(true)}
                            disabled={documentType !== "resumes"}
                        >
                            <FilterIcon color={documentType !== "resumes" ? "gray" : "#1E1E1E"} height="2.5rem" width="2.5rem" />
                        </IconButton>
                    </div>
                    
                    <Collapse in={!!statusFilter || !!sourceFilter || !!targetFilter} mountOnEnter unmountOnExit>
                        <div className="filters-chips">
                            {sourceFilter && 
                                <Chip 
                                    sx={{
                                        fontSize:"0.8rem",
                                        height:"1.75rem", 
                                        padding:"0rem 0.3rem", 
                                        marginRight:"0.5rem",
                                        "& .MuiSvgIcon-root" : {
                                            height:"65%"
                                        }
                                    }}    
                                    label={sourceFilter === "manual" ? "Manual" : "Via Link"}
                                    onDelete={()=>{
                                        setSourceFilter('');
                                        updateSearchParams({'source': ''});
                                    }}
                                />
                            }
                            {targetFilter && 
                                <Chip 
                                    sx={{
                                        fontSize:"0.8rem",
                                        height:"1.75rem", 
                                        padding:"0rem 0.3rem", 
                                        marginRight:"0.5rem",
                                        "& .MuiSvgIcon-root" : {
                                            height:"65%"
                                        }
                                    }}    
                                    label={targetFilter}
                                    onDelete={()=>{
                                        setTargetFilter('');
                                        updateSearchParams({'target': ''});
                                    }}
                                />
                            }
                        </div>
                    </Collapse>

                    <Suspense fallback={<LoadingIndicator type="text" />}>
                        <CustomGrid 
                            gridId={`${documentType}Table`}
                            rowData={isLoading ? null : documents}
                            columnDefs={columnDefs}
                            rowSelection='multiple'
                            suppressRowClickSelection
                            onRowSelected={handleRowSelected}
                            isExternalFilterPresent={isExternalFilterPresent}
                            doesExternalFilterPass={doesExternalFilterPass}
                        >
                            <Fade in={isStale} mountOnEnter unmountOnExit>
                                <div
                                    style={{
                                        position:"absolute",
                                        width:"calc(95% - 1rem)",
                                        display:"flex",
                                        justifyContent:"center",
                                        zIndex:"5",
                                        marginTop:"3.3rem",
                                    }}
                                >
                                    <Chip 
                                        icon={<AutorenewOutlined style={{color:"white"}} />} 
                                        onClick={syncDocuments} 
                                        style={{background:"#EA723C", font:"inherit", color:"white"}} 
                                        label={"Load new documents"} 
                                    />
                                </div>
                            </Fade>
                        </CustomGrid>
                    </Suspense>
                </ResponsiveWrapper>
            </div>
        </>
    )
}

export default DocumentList;