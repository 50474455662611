import styled from "@emotion/styled";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { MenuItem, Select } from "@mui/material";

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
  })(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: "#EA723C80",
      color: "black",
      '&:hover, &:focus': {
        backgroundColor: "#EA723C80",
      },
    }),
    ...(isHovered && {
      backgroundColor: "#EA723C18",
      '&:hover, &:focus': {
        backgroundColor: "#EA723C18",
      },
    }),
    ...(day.day() === 0 && {
      borderTopLeftRadius: '25%',
      borderBottomLeftRadius: '25%',
    }),
    ...(day.day() === 6 && {
      borderTopRightRadius: '25%',
      borderBottomRightRadius: '25%',
    }),
  }));
  
const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
      return false;
    }
    return dayA.isSame(dayB, 'week');
  };

const Day = (props) => {
    const { day, selectedDay, hoveredDay, ...other } = props;
  
    return (
      <CustomPickersDay
        {...other}
        day={day}
        sx={{ px: 2.5 }}
        disableMargin
        selected={false}
        isSelected={isInSameWeek(day, selectedDay)}
        isHovered={isInSameWeek(day, hoveredDay)}
      />
    );
}

const customDatePickerTheme = createTheme({
    palette : {
        primary : {
            main : "#EA723C"
        },
        secondary : {
            main : "#000"
        }
    },
    components: {
        MuiTextField: {
        styleOverrides: {
            root: {
                height:"2rem",
                borderRadius: '8px',
                backgroundColor: '#EA723C80',
                input : {
                    fontFamily:"inherit",
                    fontSize:"0.8rem",
                    fontWeight:"600",
                    width: "4.2rem",
                    height:"2rem",
                    padding:"0rem 0.5rem",
                    color:"#0000007F",
                    transition:"color ease-in-out 300ms"
                },
                "& .Mui-focused input" : {
                    color:"#000000D3"
                },
                fieldset : {
                    borderRadius:"8px",
                    border: "none",
                },
                "& .MuiButtonBase-root" : {
                    width:"1.5rem"
                },
                "& .MuiSvgIcon-root" : {
                    fill : "#0000007f",
                    transition:"fill ease-in-out 300ms",
                    fontSize:"1.2rem",
                },
                "& .Mui-focused .MuiSvgIcon-root" : {
                    fill : "#000000D3"
                }
            }
        }},
        MuiSelect: {
            styleOverrides: {
                root: {
                    height:"2rem",
                    width: "8rem",
                    textTransform:"capitalize",
                    fontFamily:"inherit",
                    fontSize:"0.8rem",
                    fontWeight:"600",
                    borderRadius: '8px',
                    backgroundColor: '#EA723C',
                    padding:"0rem 0.5rem",
                    color:"#ffffff7f",
                    transition:"color ease-in-out 300ms",
                    "&.Mui-Focused" : {
                        color:"#ffffffD3"
                    },
                    fieldset : {
                        borderRadius:"8px",
                        border: "none",
                    },
                    "& .MuiButtonBase-root" : {
                        width:"1.5rem"
                    },
                    "& .MuiSvgIcon-root" : {
                        fill : "#ffffff7f",
                        transition:"fill ease-in-out 300ms",
                        fontSize:"1.2rem",
                    },
                    "& .Mui-focused .MuiSvgIcon-root" : {
                        fill : "#ffffffD3"
                    }
                }
            }
        }
}})

const intervalOptions = ["weekly", "monthly"]

const IntervalPicker = ({value, onChange=()=>{}, ...props}) => {
    return (
        <Select
            {...props}
            displayEmpty
            value={value}
            onChange={onChange}
        >
        {intervalOptions.map((name, index) => (
            <MenuItem
                value={name}
                key={index}
                style={{ textTransform: "capitalize" }}
            >
                {name}
            </MenuItem>
            ))
        }
        </Select>
    )
}

const CustomDatePicker = ({value=dayjs(), onChange, inputType = "date", ...props}) => {
    const [hoveredDay, setHoveredDay] = useState(null);

    const defaultProps = {
        showDaysOutsideCurrentMonth:(inputType === "week"),
        displayWeekNumber:(inputType === "week"),
        slots: inputType === "week" ? {day : Day} : {},
    }
    
    const getFormat = () => {
        switch(inputType){
            case "date" : return "DD/MM/YY";
            case "week" : return "MMM DD";
            case "month": return "MMM YY";
            case "year" : return "YYYY";
            default     : return null;
        }
    }

    const getViews = () => {
        switch(inputType){
            case "date":
            case "week"  : return ["year", "month", "day"];
            case "month" : return ["year", "month"];
            case "year"  : return ["year"];
            default      : return null;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                value={value}
                onChange={(newValue) => onChange(newValue)}
                views={getViews()}
                openTo={getViews()?.[getViews().length -1]}
                format={getFormat()}
                slotProps={{
                    day : (ownerState) => ({
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: ()=> setHoveredDay(ownerState.day),
                        onPointerLeave: ()=> setHoveredDay(null)
                    })
                }}
                {...defaultProps}
                {...props}
                />
        </LocalizationProvider>
    )
}

export {IntervalPicker, CustomDatePicker, customDatePickerTheme};