const ShortlistedIcon = ({color="#1E1E1E", ...params}) => {
    return (
        <svg width="33" height="35" viewBox="0 0 33 35" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 9.78946V5.23914C23 3.24079 23 2.24162 23.659 1.62081C24.318 1 25.3787 1 27.5 1C29.6213 1 30.682 1 31.341 1.62081C32 2.24162 32 3.24079 32 5.23914V9.78946C32 11.0577 32 11.6918 31.5653 11.9188C30.7234 12.3583 29.1443 10.8919 28.3944 10.4503C27.9594 10.1943 27.742 10.0662 27.5 10.0662C27.258 10.0662 27.0406 10.1943 26.6056 10.4503C25.8557 10.8919 24.2766 12.3583 23.4347 11.9188C23 11.6918 23 11.0577 23 9.78946Z" fill="transparent" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.0002 2C21.0002 2 20.0002 2 18.3093 2C13.2719 2 10.7532 2 8.74157 2.70616C5.50761 3.84141 2.9567 6.24228 1.75049 9.286C1.00019 11.1793 1.00019 13.5498 1.00019 18.2909V22.3636C1.00019 27.2746 1.00019 29.7301 2.35651 31.4354C2.74512 31.924 3.20599 32.3577 3.72511 32.7235C5.53694 34 8.1459 34 13.3638 34H14.6002C21.0113 34 24.2168 34 26.2085 32.1255C28.2002 30.251 28.2002 27.234 28.2002 21.2V13" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 18C1 15.0545 3.38781 12.6667 6.33333 12.6667C7.39859 12.6667 8.65446 12.8533 9.69018 12.5758C10.6104 12.3292 11.3292 11.6104 11.5758 10.6902C11.8533 9.65446 11.6667 8.39859 11.6667 7.33333C11.6667 4.38782 14.0545 2 17 2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 26L9.8 26" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 19.5996L16.2 19.5996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ShortlistedIcon;