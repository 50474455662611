const CopyLinkIcon = ({color="white", ...params}) => {
    return (
        <svg width="28" height="31" viewBox="0 0 28 31" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_90_7657" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="-1" y="0" width="29" height="31">
        <rect x="-0.00390625" y="0.666748" width="28" height="29.4" fill={color}/>
        </mask>
        <g mask="url(#mask0_90_7657)">
        <path d="M16.6489 17.0968C15.568 18.1777 13.8155 18.1777 12.7345 17.0968C11.6536 16.0158 11.6536 14.2633 12.7345 13.1824L15.181 10.7359C16.2128 9.70412 17.8563 9.65716 18.9438 10.595M18.6061 7.31078C19.6871 6.22984 21.4396 6.22984 22.5205 7.31078C23.6015 8.39171 23.6015 10.1443 22.5205 11.2252L20.074 13.6717C19.0423 14.7034 17.3987 14.7504 16.3113 13.8126" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M23.3307 16.8705C23.3307 20.7814 23.3307 22.7368 22.1158 23.9518C20.9008 25.1668 18.9453 25.1668 15.0344 25.1668H12.9604C9.04945 25.1668 7.09399 25.1668 5.87903 23.9518C4.66406 22.7368 4.66406 20.7814 4.66406 16.8705V14.7964C4.66406 10.8855 4.66406 8.93004 5.87903 7.71507C7.09399 6.50011 9.04945 6.50011 12.9604 6.50011" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </g>
        </svg>
    )
}

export default CopyLinkIcon;